import { ShippingAddress } from "@quickcommerceltd/zappboard";

export function formatAddress(address: ShippingAddress) {
  return address
    ? `${address?.streetAddress1} ${address?.streetAddress2}`.substr(0, 30)
    : "—";
}

export function formatName(address: ShippingAddress) {
  return `${address.firstName} ${address.lastName}`;
}
