import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { Device } from "@quickcommerceltd/zipp";
import cx from "classnames";
import { FC, useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { functions } from "../../firebase";
import firestore from "../../firebase/firestore";
import { usePickingDeviceAssignmentModalStyles } from "./PickingDeviceAssignmentModal.style";

interface Props {
  open: boolean;
  orderId: string;
  warehouseId: string;
  onClose: () => void;
}

export const PickingDeviceAssignmentModal: FC<Props> = ({
  open,
  orderId,
  warehouseId,
  onClose,
}) => {
  const classes = usePickingDeviceAssignmentModalStyles();
  const [activeDeviceId, setActiveDeviceId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pickingDevices] = useCollectionData<Device>(
    firestore
      .collection("pickingDevices")
      .where("warehouseId", "==", warehouseId)
      .where("isOnline", "==", true),
  );

  const currentPickingDevice = useCollectionData<Device>(
    firestore
      .collection("pickingDevices")
      .where("task.id" as any, "==", orderId),
  )?.[0]?.[0];

  useEffect(() => {
    setActiveDeviceId(currentPickingDevice?.id);
  }, [currentPickingDevice, setActiveDeviceId]);

  const handleOnClose = () => {
    onClose();
  };

  const handleActiveDeviceChange = (event: any) => {
    setActiveDeviceId(event.target.value);
  };

  const submitDeviceAssignment = () => {
    if (loading) return;
    setLoading(true);
    setError("");

    const assignOrderToPickingDevice = functions.httpsCallable(
      "api-assignOrderToPickingDevice",
    );

    const taskId = orderId;
    const deviceId = activeDeviceId;
    assignOrderToPickingDevice({ taskId, deviceId })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setError(`Error: Could not assign order to device: ${error?.message}`);
      });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Assign Order to Picker</DialogTitle>
      <DialogContent className={classes.paper}>
        <CloseIcon onClick={handleOnClose} className={classes.closeIcon} />

        {currentPickingDevice && (
          <Box mb={2}>
            <Alert severity="warning" variant="filled">
              Currently in picking by: {currentPickingDevice?.userName}{" "}
              {!currentPickingDevice?.isOnline && "(Offline)"}
            </Alert>
          </Box>
        )}

        <FormControl
          variant="outlined"
          className={cx(classes.select, classes.formControl)}
          required
        >
          <InputLabel>Select a Picker</InputLabel>
          <Select
            id="code"
            label="Select a Picker"
            value={activeDeviceId || currentPickingDevice?.id || ""}
            onChange={handleActiveDeviceChange}
          >
            <MenuItem value="" disabled>
              Select a Picker
            </MenuItem>
            {currentPickingDevice && (
              <MenuItem value={currentPickingDevice.id}>
                {currentPickingDevice.userName}
              </MenuItem>
            )}
            {pickingDevices?.map((device: any) => (
              <MenuItem key={device.id} value={device.id}>
                {device.userName} {device.task && `(${atob(device.task.id)})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={submitDeviceAssignment}
          fullWidth
          disabled={
            !activeDeviceId || currentPickingDevice?.id === activeDeviceId
          }
          className={classes.button}
          children={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Assign Order to Picker"
            )
          }
        />

        {error && (
          <Typography
            variant="body2"
            color="error"
            style={{ textAlign: "center" }}
          >
            {error}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
