import { FC, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { TextField, useSearchbarStyles } from "./Searchbar.style";
import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import debounce from "lodash/debounce";

const EMPTY_TERM = "";
const DEBOUNCE_TIME = 500;

interface Props {
  onSearch: (term: string) => void;
}

export const Searchbar: FC<Props> = ({ onSearch }) => {
  const classes = useSearchbarStyles();
  const [value, setValue] = useState(EMPTY_TERM);

  const search = useMemo(
    () =>
      debounce((term: string) => {
        !!onSearch && onSearch(term);
      }, DEBOUNCE_TIME),
    [onSearch],
  );

  useEffect(() => {
    search(value);
  }, [value, search]);

  const setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event?.target?.value ?? EMPTY_TERM;
    setValue(searchTerm);
  };

  const deleteSearchTerm = () => {
    setValue(EMPTY_TERM);
  };

  return (
    <div className={cx(classes.wrapper)}>
      <TextField
        name="searchbar"
        type="text"
        variant="outlined"
        size="small"
        placeholder="Search by Order Number, Address, Status"
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
          endAdornment: value && (
            <IconButton onClick={deleteSearchTerm}>
              <CloseIcon />
            </IconButton>
          ),
        }}
        value={value}
        onChange={setSearchTerm}
      />
    </div>
  );
};
