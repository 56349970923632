import { Route, Switch } from "react-router";
import Riders from "./Riders";
import Home from "./Home";
import routes from "./routes";
import Signin from "./SignIn";
import Warehouse from "./Warehouse";
import Manager from "./Manager";
import Authorize from "./Authorize";
import { OnlineStatusProvider } from "../providers/OnlineStatusProvider";
import { OnlineStatusNotificationbar } from "../components/OnlineStatusNotificationbar/OnlineStatusNotificationbar";

const App = () => {
  return (
    <OnlineStatusProvider>
      <OnlineStatusNotificationbar />
      <Switch>
        <Route exact path={routes.authorize} children={<Authorize />} />
        <Route exact path={routes.warehouse} children={<Warehouse />} />
        <Route exact path={routes.statistics} children={<Warehouse />} />
        <Route exact path={routes.manager} children={<Manager />} />
        <Route exact path={routes.riders} children={<Riders />} />
        <Route path={routes.signin} children={<Signin />} />
        <Route path={routes.home} children={<Home />} />
      </Switch>
    </OnlineStatusProvider>
  );
};

export default App;
