import cx from "classnames";
import { Duration } from "luxon";
import { Order } from "@quickcommerceltd/zappboard";
import { FC, useMemo } from "react";
import { useLocation, useParams } from "react-router";
import AppBar from "../components/AppBar";
import { Card, cardStyles } from "../components/Card";
import { Description, Number, Time, Title, Subtitle } from "../components/Text";
import firestore from "../firebase/firestore";
import routes from "./routes";
import { withAuthentication } from "./withAuthentication";
import { Button, Box, CircularProgress } from "@material-ui/core";
import { firebase } from "../firebase";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import WarehouseCard from "../components/WarehouseCard/WarehouseCard";
import { OrdersList } from "../components/OrdersList/OrdersList";
import { ORDERS_PERIOD_MS } from "../config";
import { SoundEffect } from "../components/SoundEffect";
import {
  FulfillmentStatus,
  PickingStatus,
  TaskState,
} from "@quickcommerceltd/zappboard";
import {
  RevenueAndWorkloadStats,
  RevenueWarehouseStats,
} from "../components/RevenueAndWorkloadStats/RevenueAndWorkloadStats";
import { remoteConfig } from "../firebase";
import { sessionState } from "../state";
import { useRecoilState } from "recoil";

const UNFULFILLED_DANGER = 5; //minutes
const CANCELLED_DANGER = 5;
const UNASSIGNED_WARNING = 3;
const UNASSIGNED_DANGER = 8;
const WAITING_WARNING = 1;
const WAITING_DANGER = 2;
const PICKING_WARNING = 2;
const PICKING_DANGER = 4;
const ASSIGNED_WARNING = 3;
const ASSIGNED_DANGER = 8;
const ACTIVE_WARNING = 3;
const ACTIVE_DANGER = 8;

const MINUTE_IN_MS = 60000;
const DELIVERY_10AVG_WARNING = MINUTE_IN_MS * 15;
const DELIVERY_10AVG_DANGER = MINUTE_IN_MS * 18;
const DELIVERED_ON_TIME_DANGER = 85; //percent
const DELIVERED_ON_TIME_WARNING = 90; //percent

const DELIVERED_TO_PROMISE_DANGER = 90; //percent
const DELIVERED_TO_PROMISE_WARNING = 95; //percent

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Warehouse: FC = () => {
  const [session] = useRecoilState(sessionState);

  const classes = cardStyles();
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const orderId = query.get("orderId") ?? undefined;

  const [stats, loading, error] = useDocumentData<RevenueWarehouseStats>(
    firestore.doc(`warehouseStats/${id}`),
  );

  const now = useMemo(() => firebase.firestore.Timestamp.now().toMillis(), []);

  const [orders] = useCollectionData<Order>(
    firestore
      .doc(`warehouseStats/${id}`)
      .collection(`orders`)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">", now - ORDERS_PERIOD_MS),
  );

  const orderStats = useMemo(() => {
    const unfulfilledOrders = orders?.filter(
      (o) => o.fulfillmentStatus === FulfillmentStatus.UNFULFILLED,
    );
    return {
      waiting:
        unfulfilledOrders?.filter((o) => o.pickingStatus === PickingStatus.OPEN)
          ?.length ?? 0,
      picking:
        unfulfilledOrders?.filter(
          (o) => o.pickingStatus === PickingStatus.PICKING,
        )?.length ?? 0,
      unassigned:
        unfulfilledOrders?.filter(
          (o) => o.deliveryStatus === TaskState.UNASSIGNED,
        )?.length ?? 0,
      assigned:
        unfulfilledOrders?.filter(
          (o) => o.deliveryStatus === TaskState.ASSIGNED,
        )?.length ?? 0,
      active:
        unfulfilledOrders?.filter((o) => o.deliveryStatus === TaskState.ACTIVE)
          ?.length ?? 0,
    };
  }, [orders]);

  if (process.env.NODE_ENV === "development") {
    console.log("warehouse", stats, orders);
  }

  const isRevenueStatsEnabled = useMemo(() => {
    try {
      const revenueStatsEnabledFor = remoteConfig.getString(
        "revenueStatsEnabledFor",
      );
      return revenueStatsEnabledFor.includes(session.user?.email as string);
    } catch (error) {
      return false;
    }
  }, [session.user?.email]);

  if (error) {
    return <Box padding={4}>{error.message}</Box>;
  }

  if (!loading && !stats) {
    return (
      <>
        <AppBar title={`${id}`} backTo={routes.home} />
        <Box display="flex" justifyContent="center" padding={4}>
          <Title>Not found</Title>
        </Box>
      </>
    );
  }

  if (!stats || !orders) {
    return (
      <>
        <AppBar title={`${id}`} backTo={routes.home} />
        <Box display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  const avgCompletionTime = Duration.fromMillis(
    stats.picking?.avgTotalTime10OrdersMs,
  );

  return (
    <>
      <SoundEffect value={orderStats.waiting} />
      <AppBar
        title={`${stats.shortName} · ${stats.pickUpAddress?.city}`}
        backTo={routes.home}
        actions={
          <Box display="none">
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={routes.manager.replace(":id", id)}
            >
              Manager
            </Button>
            <Box mx={1} />
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={routes.riders.replace(":id", id)}
            >
              Riders
            </Button>
          </Box>
        }
      />

      <Box marginTop={2} marginBottom={4} mx={2}>
        <Box display="flex" m={-1} className={classes.tiles}>
          <Box m={1} flex={1} display="flex" className={classes.headTile}>
            <Box display="flex" flex={1} height="1">
              <Box m={-1} display="flex" flex={1} className={classes.head}>
                <Box m={1} flex={1}>
                  {stats && <WarehouseCard stats={stats} />}
                </Box>

                <Box m={1} flex={1}>
                  <Card className={cx(classes.summaryCard)}>
                    <Description>Today (from 00:00)</Description>
                    <Box display="flex" className={classes.paperIdle} mb={-1}>
                      <Box flex={1} m={1}>
                        <Number size="small">
                          {stats.orders?.fulfilledOrdersNum || 0}
                        </Number>
                        <Subtitle>Fulfilled</Subtitle>
                      </Box>
                      <Box
                        flex={1}
                        m={1}
                        className={cx(
                          stats.orders?.cancelledOrdersNum > CANCELLED_DANGER
                            ? classes.paperDanger
                            : classes.paperIdle,
                        )}
                      >
                        <Number size="small">
                          {stats.orders?.cancelledOrdersNum || 0}
                        </Number>
                        <Subtitle>Cancelled</Subtitle>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box m={1} flex={1} className={classes.totals}>
            <Box height="1" mx={-1} display="flex">
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    stats.orders.unfulfilledOrdersNum < UNFULFILLED_DANGER
                      ? classes.idle
                      : stats.orders.unfulfilledOrdersNum < UNFULFILLED_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item,
                  )}
                >
                  <Title>Unfulfilled</Title>
                  <Number>{stats.orders.unfulfilledOrdersNum || 0}</Number>
                  <Subtitle>Unfulfilled Orders</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.unassigned < UNASSIGNED_WARNING
                      ? classes.idle
                      : orderStats.unassigned < UNASSIGNED_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item,
                  )}
                >
                  <Title>Unassigned</Title>
                  <Number>{orderStats.unassigned}</Number>
                  <Subtitle>Unassigned tasks</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.waiting < WAITING_WARNING
                      ? classes.idle
                      : orderStats.waiting < WAITING_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item,
                  )}
                >
                  <Title>Waiting</Title>
                  <Number>{orderStats.waiting}</Number>
                  <Subtitle>Orders waiting to be picked</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.picking < PICKING_WARNING
                      ? classes.idle
                      : orderStats.picking < PICKING_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item,
                  )}
                >
                  <Title>Picking</Title>
                  <Number>{orderStats.picking}</Number>
                  <Subtitle>Orders in picking</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.assigned < ASSIGNED_WARNING
                      ? classes.idle
                      : orderStats.assigned < ASSIGNED_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item,
                  )}
                >
                  <Title>Assigned</Title>
                  <Number>{orderStats.assigned}</Number>
                  <Subtitle>Assigned tasks</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.active < ACTIVE_WARNING
                      ? classes.idle
                      : orderStats.active < ACTIVE_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item,
                  )}
                >
                  <Title>In Delivery</Title>
                  <Number>{orderStats.active}</Number>
                  <Subtitle>Active tasks</Subtitle>
                </Card>
              </Box>
            </Box>
          </Box>

          <Box m={1} flex={0.5} display="flex" className={classes.timersTile}>
            <Box m={-1} flex="1" display="flex">
              <Box height="1" flex="1" display="flex" flexDirection="column">
                <Box textAlign="center" m={1} flex="1">
                  <Card
                    className={cx(
                      classes.time,
                      stats.tasks
                        ?.completedTasksWithinThrottledPromisePercent <=
                        DELIVERED_TO_PROMISE_DANGER
                        ? classes.danger
                        : stats.tasks
                            ?.completedTasksWithinThrottledPromisePercent <=
                          DELIVERED_TO_PROMISE_WARNING
                        ? classes.warning
                        : classes.idle,
                    )}
                  >
                    <Time>
                      {stats.tasks?.completedTasksWithinThrottledPromisePercent?.toFixed(
                        0,
                      )}
                      %
                    </Time>
                    <Subtitle>Delivered to promise</Subtitle>
                    <Description>Today (from 00:00)</Description>
                  </Card>
                </Box>

                <Box textAlign="center" m={1} flex="1">
                  <Card
                    className={cx(
                      stats.picking?.avgTotalTime10OrdersMs <
                        DELIVERY_10AVG_WARNING
                        ? classes.idle
                        : stats.picking?.avgTotalTime10OrdersMs <
                          DELIVERY_10AVG_DANGER
                        ? classes.warning
                        : classes.danger,
                      classes.time,
                    )}
                  >
                    <Time>{avgCompletionTime.toFormat("hh:mm:ss")}</Time>
                    <Subtitle>Delivery Avg Duration</Subtitle>
                    <Description>Last 10 orders</Description>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box m={1} flex={0.5} display="flex" className={classes.timersTile}>
            <Box m={-1} flex="1" display="flex">
              <Box height="1" flex="1" display="flex" flexDirection="column">
                <Box textAlign="center" m={1} flex="1">
                  <Card
                    className={cx(
                      classes.time,
                      stats.tasks?.completedTasksWithinPromisePercent <=
                        DELIVERED_ON_TIME_DANGER
                        ? classes.danger
                        : stats.tasks?.completedTasksWithinPromisePercent <=
                          DELIVERED_ON_TIME_WARNING
                        ? classes.warning
                        : classes.idle,
                    )}
                  >
                    <Time>
                      {stats.tasks?.completedTasksWithinPromisePercent?.toFixed(
                        0,
                      )}
                      %
                    </Time>
                    <Subtitle>Delivered on time</Subtitle>
                    <Description>Today (from 00:00)</Description>
                  </Card>
                </Box>

                <Box textAlign="center" m={1} flex="1">
                  <Card className={cx(classes.time, classes.idle)}>
                    <Time>{stats.tasks?.ridersOnline ?? 0}</Time>
                    <Subtitle>Riders online</Subtitle>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mb={4} />
        {isRevenueStatsEnabled && (
          <Box paddingBottom="2vh">
            <RevenueAndWorkloadStats warehouses={[stats]} />
          </Box>
        )}
        <Box>
          <OrdersList selectedOrderId={orderId} orders={orders} />
        </Box>
      </Box>
    </>
  );
};

export default withAuthentication(Warehouse);
