import { Duration } from "luxon";
import cx from "classnames";
import { alpha, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: ".95em",
    transition: "all 0.15s ease-in-out",
  },
  highlight: {
    background: alpha(theme.palette.primary.light, 0.5),
    "& > $value": {
      transition: "all 0s ease-in-out",
      transform: "scale(1.2)",
    },
  },
  value: {
    display: "block",
    transformOrigin: "right",
    transition: "all 0.15s ease-in-out",
  },
}));

export const ActiveFormatDuration = React.memo(
  ({ value, ...props }: { value: number }) => {
    const classes = useStyles();
    const [isChanged, setIsChanged] = useState(false);
    const wasUpdated = useMemo(() => {
      return debounce(() => {
        setIsChanged(false);
      }, 500);
    }, []);

    useEffect(() => {
      setIsChanged(true);
      wasUpdated();
      return () => wasUpdated.cancel();
    }, [wasUpdated]);

    const duration = Duration.fromMillis(value).toFormat("hh:mm:ss");

    return (
      <span
        {...props}
        className={cx(classes.root, {
          [classes.highlight]: isChanged,
        })}
      >
        {duration}
      </span>
    );
  },
);

export default ActiveFormatDuration;
