import { OrderEventsEmailsEnum } from "../_types/OrderEventsEmailsEnum";
import { OrderEventsEnum } from "../_types/OrderEventsEnum";

export interface OrderHistoryEvent {
  date: string;
  link: string | undefined;
  message: string;
}

export const parseOrderHistoryEvent = (event: any): OrderHistoryEvent => {
  return {
    date: event?.date || "",
    link: event?.ticketUrl ?? getLinkFromMessage(event.message),
    message: parseOrderHistoryMessage(event),
  };
};

const getLinkFromMessage = (message: string | null): string | undefined => {
  if (!message?.includes("href=")) {
    return undefined;
  }
  let linkURL = message.split("href='")[1].split("'>")[0];
  return linkURL;
};

const parseOrderHistoryMessage = (event: any): string => {
  // from Saleor-Dashboard
  switch (event.type) {
    case OrderEventsEnum.CANCELED:
      return "Order was cancelled";
    case OrderEventsEnum.DRAFT_ADDED_PRODUCTS:
      return "Products were added to draft order";
    case OrderEventsEnum.DRAFT_CREATED:
      return "Draft order was created";
    case OrderEventsEnum.DRAFT_REMOVED_PRODUCTS:
      return "Products were deleted from draft order";
    // @ts-expect-error (it is working in Saleor-dashboard this way)
    case OrderEventsEnum.EMAIL_SENT:
      switch (event.emailType) {
        case OrderEventsEmailsEnum.DIGITAL_LINKS:
          return "Links to the order's digital goods were sent";
        case OrderEventsEmailsEnum.FULFILLMENT_CONFIRMATION:
          return "Fulfillment confirmation was sent to customer";
        case OrderEventsEmailsEnum.ORDER_CONFIRMATION:
          return "Order confirmation was sent to customer";
        case OrderEventsEmailsEnum.PAYMENT_CONFIRMATION:
          return "Payment confirmation was sent to customer";
        case OrderEventsEmailsEnum.SHIPPING_CONFIRMATION:
          return "Shipping details was sent to customer";
        case OrderEventsEmailsEnum.TRACKING_UPDATED:
          return "Shipping tracking number was sent to customer";
        case OrderEventsEmailsEnum.ORDER_CANCEL:
          return "Order cancel information was sent to customer";
        case OrderEventsEmailsEnum.ORDER_REFUND:
          return "Order refund information was sent to customer";
      } // eslint-disable-next-line
    case OrderEventsEnum.FULFILLMENT_CANCELED:
      return "Fulfillment was cancelled";
    case OrderEventsEnum.INVOICE_REQUESTED:
      return "Invoice was requested";
    case OrderEventsEnum.INVOICE_GENERATED:
      const requesterInvoiceGenerated = event.invoiceNumber
        ? `Invoice no. ${event.invoiceNumber ?? ""} was generated`
        : "Invoice was generated";
      return requesterInvoiceGenerated;
    case OrderEventsEnum.INVOICE_UPDATED:
      const requesterInvoiceUpdated = event.invoiceNumber
        ? `Invoice no. ${event.invoiceNumber ?? ""} was updated`
        : "Invoice was updated";
      return requesterInvoiceUpdated;
    case OrderEventsEnum.INVOICE_SENT:
      return "Invoice was sent to customer";
    case OrderEventsEnum.FULFILLMENT_FULFILLED_ITEMS:
      return `Fulfilled ${event.quantity ?? ""} items`;
    case OrderEventsEnum.FULFILLMENT_RESTOCKED_ITEMS:
      return `Restocked ${event.quantity ?? ""} items`;
    case OrderEventsEnum.NOTE_ADDED:
      const message = event.message ?? "Note was added to the order";
      return message?.includes("href")
        ? message.split(">")[1].split("<")[0]
        : message;
    case OrderEventsEnum.ORDER_FULLY_PAID:
      return "Order was fully paid";
    case OrderEventsEnum.ORDER_MARKED_AS_PAID:
      return "Marked order as paid";
    case OrderEventsEnum.OTHER:
      return event.message ?? "Undefined message";
    case OrderEventsEnum.OVERSOLD_ITEMS:
      return `Oversold ${event.quantity ?? ""} items`;
    case OrderEventsEnum.PAYMENT_CAPTURED:
      return "Payment was captured";
    case OrderEventsEnum.PAYMENT_FAILED:
      return "Payment failed";
    case OrderEventsEnum.PAYMENT_REFUNDED:
      return "Payment was refunded";
    case OrderEventsEnum.PAYMENT_VOIDED:
      return "Payment was voided";
    case OrderEventsEnum.PLACED:
      return "Order was placed";
    case OrderEventsEnum.PLACED_FROM_DRAFT:
      return "Order was created from draft";
    case OrderEventsEnum.TRACKING_UPDATED:
      return "Updated fulfillment group's tracking number";
    case OrderEventsEnum.UPDATED_ADDRESS:
      return "Order address was updated";
    case OrderEventsEnum.PAYMENT_AUTHORIZED:
      return "Payment was authorized";
    case OrderEventsEnum.EXTERNAL_SERVICE_NOTIFICATION:
      return event.message ?? "Undefined external service notification";
    case OrderEventsEnum.PACKED:
      const linesPacked = event.lines
        ?.map((line: any) => `${line.orderLine.productSku}: ${line.quantity}`)
        .join(",");
      return linesPacked?.length > 0
        ? `Order packed ${linesPacked}`
        : "Order packed";
    case OrderEventsEnum.CREDIT_CREATED:
      return `Credit ${event.amount ?? ""} to customer`;
    case OrderEventsEnum.LINES_CANCELLED:
      const linesCancelled = event.lines
        ?.map((line: any) => `${line.orderLine.productSku}: ${line.quantity}`)
        .join(",");
      return linesCancelled?.length > 0
        ? `Cancelled SKUs ${linesCancelled} ${event.source ?? ""}`
        : `Cancelled SKUs ${event.source ?? ""}`;
    case OrderEventsEnum.TICKET_CREATED:
      const createdText = event.ticketId ? `: ${event.ticketId}` : "";
      return "Ticket created" + createdText;
    default:
      return "Unknown event type " + event.type;
  }
};
