import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {
  CancelReason,
  SupportInquiry,
  SupportInquiryCode,
} from "@quickcommerceltd/zappboard";
import { FC, useState } from "react";
import { SubmitButton, StyledRadio } from ".";
import { useCustomerSupportModalStyles } from "../CustomerSupportModal.style";

interface Props {
  onButtonClick: () => void;
  loading: boolean;
  setRequest: (inquiry: Partial<SupportInquiry>) => void;
}
export const CustomerSupportCancelled: FC<Props> = ({
  onButtonClick,
  loading,
  setRequest,
}) => {
  const classes = useCustomerSupportModalStyles();
  const [cancelReason, setCancelReason] = useState<CancelReason | undefined>(
    undefined,
  );

  const handelCancelReasonChange = (event: any) => {
    const reason = event.target.value;
    if (!Object.values(CancelReason).includes(reason)) {
      return;
    }
    setCancelReason(reason);
    setRequest({
      code: SupportInquiryCode.CANCEL_ORDER,
      delayReason: undefined,
      cancelReason: reason,
      message: undefined,
      outOfStockItems: undefined,
    });
  };

  return (
    <Box>
      <Typography variant="h5" className={classes.reasonHeadline}>
        Please provide the reason for the cancellation
      </Typography>

      <FormControl component="fieldset" required>
        <RadioGroup
          aria-label="cancelReason"
          name="cancelReason"
          value={undefined}
          onChange={handelCancelReasonChange}
        >
          <FormControlLabel
            className={classes.topRadio}
            value="no_riders_available"
            control={<StyledRadio />}
            label="No Riders Available"
          />
          <FormControlLabel
            value="temp_store_closure"
            control={<StyledRadio />}
            label="Temp Store Closure"
          />
          <FormControlLabel
            value="extreme_weather_conditions"
            control={<StyledRadio />}
            label="Extreme Weather Conditions"
          />
          <FormControlLabel
            value="could_not_find_customer"
            control={<StyledRadio />}
            label="Could Not Find Customer"
          />
          <FormControlLabel
            value="unresponsive_customer"
            control={<StyledRadio />}
            label="Unresponsive Customer"
          />
          <FormControlLabel
            value="whole_order_oos"
            control={<StyledRadio />}
            label="Whole Order OOS"
          />
        </RadioGroup>
      </FormControl>

      <SubmitButton
        onButtonClick={onButtonClick}
        loading={loading}
        disabled={!cancelReason}
      />
    </Box>
  );
};
