import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {
  SupportInquiry,
  SupportInquiryCode,
  DelayReason,
} from "@quickcommerceltd/zappboard";
import { FC, useState } from "react";
import { SubmitButton, StyledRadio } from ".";
import { useCustomerSupportModalStyles } from "../CustomerSupportModal.style";

interface Props {
  onButtonClick: () => void;
  loading: boolean;
  setRequest: (inquiry: Partial<SupportInquiry>) => void;
}

export const CustomerSupportDelayed: FC<Props> = ({
  onButtonClick,
  loading,
  setRequest,
}) => {
  const classes = useCustomerSupportModalStyles();
  const [delayReason, setDelayReason] = useState<DelayReason | undefined>(
    undefined,
  );

  const handelDelayReasonChange = (event: any) => {
    const reason = event.target.value;
    if (!Object.values(DelayReason).includes(reason)) {
      return;
    }
    setDelayReason(reason);
    setRequest({
      code: SupportInquiryCode.DELIVERY_DELAY,
      delayReason: reason,
      cancelReason: undefined,
      message: undefined,
      outOfStockItems: undefined,
    });
  };

  return (
    <Box>
      <Typography variant="h5" className={classes.reasonHeadline}>
        Please provide the reason for the expected delay
      </Typography>

      <FormControl component="fieldset" required>
        <RadioGroup
          aria-label="delayReason"
          name="delayReason"
          value={undefined}
          onChange={handelDelayReasonChange}
        >
          <FormControlLabel
            className={classes.topRadio}
            value="no_riders_available"
            control={<StyledRadio />}
            label="No Riders Available"
          />
          <FormControlLabel
            value="multiple_orders"
            control={<StyledRadio />}
            label="Multiple Orders"
          />
          <FormControlLabel
            value="weather_conditions"
            control={<StyledRadio />}
            label="Weather Conditions"
          />
          <FormControlLabel
            value="other"
            control={<StyledRadio />}
            label="Other"
          />
        </RadioGroup>
      </FormControl>

      <SubmitButton
        onButtonClick={onButtonClick}
        loading={loading}
        disabled={!delayReason}
      />
    </Box>
  );
};
