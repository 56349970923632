import groupBy from "lodash/groupBy";
import entries from "lodash/entries";
import { firebase } from "../firebase";
import {
  FulfillmentStatus,
  Order,
  WarehouseStats,
} from "@quickcommerceltd/zappboard";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import { useParams } from "react-router";
import AppBar from "../components/AppBar";
import { Title } from "../components/Text";
import firestore from "../firebase/firestore";
import routes from "./routes";
import { withAuthentication } from "./withAuthentication";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import RiderCard from "../components/RiderCard/RiderCard";
import { Button, Grid } from "@material-ui/core";
import WarehouseCard from "../components/WarehouseCard/WarehouseCard";
import { ORDERS_PERIOD_MS } from "../config";

const statusOrder = [
  FulfillmentStatus.UNFULFILLED,
  FulfillmentStatus.FULFILLED,
  FulfillmentStatus.CANCELLED,
  undefined,
];

const compareStatus = (a: Order, b: Order) =>
  statusOrder.indexOf(a.fulfillmentStatus) -
  statusOrder.indexOf(b.fulfillmentStatus);

const Riders: FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const [stats, loading] = useDocumentData<WarehouseStats>(
    firestore.doc(`warehouseStats/${id}`),
  );

  const now = useMemo(() => firebase.firestore.Timestamp.now().toMillis(), []);

  const [orders] = useCollectionData<Order>(
    firestore
      .doc(`warehouseStats/${id}`)
      .collection(`orders`)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">", now - ORDERS_PERIOD_MS),
  );

  const sortedOrders = useMemo(() => {
    return (orders || []).sort(compareStatus);
  }, [orders]);

  const riders = useMemo(() => {
    return entries(groupBy(sortedOrders || [], "rider.name"));
  }, [sortedOrders]);

  const backTo = routes.warehouse.replace(":id", id);

  if (!loading && !stats) {
    return (
      <>
        <AppBar title={`${id} → Riders`} backTo={backTo} />
        <Box display="flex" justifyContent="center" padding={4}>
          <Title>{`${id} not found`}</Title>
        </Box>
      </>
    );
  }

  if (!stats) {
    return (
      <>
        <AppBar title={`${id} → Riders`} backTo={backTo} />
        <Box display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (process.env.NODE_ENV === "development") {
    console.log("riders", stats, riders);
  }

  return (
    <>
      <AppBar
        title={`${stats.shortName} · ${stats.pickUpAddress.city} · Riders`}
        backTo={backTo}
        actions={
          <Box display="none">
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={routes.statistics.replace(":id", id)}
            >
              Stats
            </Button>
          </Box>
        }
      />

      <Box mx={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            {stats && <WarehouseCard stats={stats} />}
          </Grid>
          {riders.map(([riderName, orders]) => {
            return (
              <Grid key={riderName} item xs={6} sm={4} md={3} lg={2}>
                <RiderCard name={riderName} orders={orders} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default withAuthentication(Riders);
