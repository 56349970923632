import { alpha, makeStyles, Theme } from "@material-ui/core/styles";
import cx from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { Box } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import FormatDuration from "../FormatDuration";

const NECESSARY_OFFSET = 1; //Needed to use the FormatDuration component

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "2px 8px 2px 2px",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: "bold",
    transition: "all 0.15s ease-in-out",
  },
  positive: {
    color: green[900],
    background: green[200],
    fontWeight: "bold",
  },
  negative: {
    color: red[900],
    background: red[200],
    fontWeight: "bold",
  },
  highlightPositive: {
    background: alpha(green[700], 0.5),
    "& > $value": {
      transition: "all 0s ease-in-out",
      transform: "scale(1.2)",
    },
    fontWeight: "bold",
    color: green[200],
  },
  highlightNegative: {
    background: alpha(red[700], 0.5),
    "& > $value": {
      transition: "all 0s ease-in-out",
      transform: "scale(1.2)",
    },
    fontWeight: "bold",
    color: red[200],
  },
  value: {
    display: "block",
    transformOrigin: "right",
    transition: "all 0.15s ease-in-out",
  },
  empty: {
    opacity: 0.5,
  },
  icon: {
    fontSize: "18px",
  },
}));

interface Props {
  value: number;
  inverted?: boolean;
  suffix?: string;
  duration?: boolean;
  size?: string;
  className?: string;
}

export const ActiveBadgePercentage: React.FC<Props> = ({
  value = 0,
  inverted = false,
  suffix = "%",
  duration = false,
  size,
  className,
  ...props
}) => {
  const classes = useStyles();
  const isNegative = inverted ? value > 0 : value < 0;
  const [isChanged, setIsChanged] = useState(false);
  const wasUpdated = useMemo(() => {
    return debounce(() => {
      setIsChanged(false);
    }, 500);
  }, []);

  useEffect(() => {
    setIsChanged(true);
    wasUpdated();
    return () => wasUpdated.cancel();
  }, [value, wasUpdated]);

  return (
    <Box
      {...props}
      display="flex"
      alignItems="center"
      className={cx(classes.root, {
        [classes.negative]: isNegative && !isChanged,
        [classes.highlightNegative]: isNegative && isChanged,
        [classes.highlightPositive]: isChanged,
        [classes.positive]: !isChanged,
      })}
    >
      {isNegative ? (
        <ArrowDropDownIcon className={classes.icon} />
      ) : (
        <ArrowDropUpIcon className={classes.icon} />
      )}
      {duration ? (
        <>
          {!isNegative && <span>-</span>}
          <FormatDuration
            from={NECESSARY_OFFSET}
            to={Math.abs(value) + NECESSARY_OFFSET}
          />
        </>
      ) : (
        <span>
          {value.toFixed(2)}
          {suffix}
        </span>
      )}
    </Box>
  );
};

export default ActiveBadgePercentage;
