import { makeStyles, Theme } from "@material-ui/core";

export const useOrderStyles = makeStyles<any>((theme: Theme) => {
  return {
    open: { backgroundColor: theme.palette.warning.main },
    unassigned: { backgroundColor: theme.palette.error.main },
    cancelled: { backgroundColor: theme.palette.warning.main },
    fulfilled: { backgroundColor: theme.palette.success.main },
  };
});
