import { FC, useMemo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Card, cardStyles } from "../Card";
import { Description, Number, Subtitle, Time, Title } from "../Text";
import cx from "classnames";
import sum from "lodash/sum";
import { WarehouseStats } from "@quickcommerceltd/zappboard";
import { env } from "../../config";
import FormatDuration from "../FormatDuration";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";

const MINUTE_IN_MS = 60 * 1000;
const PERCENT = 100;
const DELIVERY_10AVG_WARNING = MINUTE_IN_MS * 15;
const DELIVERY_10AVG_DANGER = MINUTE_IN_MS * 18;
const DELIVERED_TO_PROMISE_DANGER = 90; //percent
const DELIVERED_TO_PROMISE_WARNING = 95; //percent
const DELIVERED_ON_TIME_DANGER = 85; //percent
const DELIVERED_ON_TIME_WARNING = 90; //percent
const NECESSARY_OFFSET = 1; //Needed to use the FormatDuration component

const useStyles = makeStyles(() => ({
  warningText: {
    color: orange[500],
    fontWeight: "bold",
  },
  errorText: {
    color: red[500],
    fontWeight: "bold",
  },
}));

export const HomeStats: FC<{ warehouses?: WarehouseStats[] }> = ({
  warehouses,
}) => {
  const classes = { ...cardStyles(), ...useStyles() };
  const stats = useMemo(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("warehouses", warehouses);
    }

    const acc = {
      fulfilledOrdersNum: 0,
      unfulfilledOrdersNum: 0,
      cancelledOrdersNum: 0,
      unassigned: 0,
      waiting: 0,
      assigned: 0,
      picking: 0,
      active: 0,
      completed: 0,
      deliveredOnTime: 0,
      deliveredToPromise: 0,
      ridersOnline: 0,
    };

    const packingTime10OrdersMs: number[] = [];
    const totalTime10OrdersMs: number[] = [];

    warehouses?.forEach((w) => {
      acc.fulfilledOrdersNum += w.orders?.fulfilledOrdersNum ?? 0;
      acc.unfulfilledOrdersNum += w.orders?.unfulfilledOrdersNum ?? 0;
      acc.cancelledOrdersNum += w.orders?.cancelledOrdersNum ?? 0;
      acc.unassigned += w.tasks?.unassignedTasksNum ?? 0;
      acc.waiting += w.picking?.waitingOrdersNum ?? 0;
      acc.picking += w.picking?.packingOrdersNum ?? 0;
      acc.assigned += w.tasks?.assignedTasksNum ?? 0;
      acc.active += w.tasks?.activeTasksNum ?? 0;
      acc.completed += w.tasks?.completedTasksNum ?? 0;
      acc.deliveredOnTime += w.tasks?.completedTasksWithinPromiseNum ?? 0;
      acc.deliveredToPromise +=
        w.tasks?.completedTasksWithinThrottledPromiseNum ?? 0;
      acc.ridersOnline += w.tasks?.ridersOnline ?? 0;

      if (w.picking?.avgPackingTime10OrdersMs) {
        packingTime10OrdersMs.push(w.picking?.avgPackingTime10OrdersMs);
      }
      if (w.picking?.avgTotalTime10OrdersMs) {
        totalTime10OrdersMs.push(w.picking?.avgTotalTime10OrdersMs);
      }
    });

    const calculateAverage = (list: number[] = []): number => {
      return list?.length ? sum(list) / list.length : 0;
    };

    return {
      ...acc,
      avgPackingTime10OrdersMs: calculateAverage(packingTime10OrdersMs),
      avgTotalTime10OrdersMs: calculateAverage(totalTime10OrdersMs),
      deliveredOnTimePercent:
        (acc.deliveredOnTime / acc.completed) * PERCENT || 0,
      deliveredToPromisePercent:
        (acc.deliveredToPromise / acc.completed) * PERCENT || 0,
    };
  }, [warehouses]);

  const avgCompletionTime = stats.avgTotalTime10OrdersMs;

  return (
    <Box display="flex" m={-1} className={classes.tiles} fontSize=".9em">
      <Box m={1} flex={1} display="flex" className={classes.headTile}>
        <Box display="flex" flex={1} height="1">
          <Box m={-1} display="flex" flex={1} className={classes.head}>
            <Box m={1} flex={1}>
              <Card className={cx(classes.card)}>
                <Box fontSize="2em">{`${env}`.toUpperCase()}</Box>
              </Card>
            </Box>

            <Box m={1} flex={1}>
              <Card className={cx(classes.card)}>
                <Description>Today (from 00:00)</Description>
                <Box display="flex" margin={-1} mt={0}>
                  <Box flex={1} m={1}>
                    <Number size="small">{stats.fulfilledOrdersNum}</Number>
                    <Subtitle>Fulfilled</Subtitle>
                  </Box>
                  <Box flex={1} m={1} className={cx()}>
                    <Number size="small">
                      {stats.cancelledOrdersNum || 0}
                    </Number>
                    <Subtitle>Cancelled</Subtitle>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box m={1} flex={1} className={classes.totals}>
        <Box height="1" mx={-1} display="flex">
          <Box flex={1} mx={1}>
            <Card className={cx(classes.item)}>
              <Title>Unfulfilled</Title>
              <Number size="medium">{stats.unfulfilledOrdersNum || 0}</Number>
              <Subtitle>Unfulfilled orders</Subtitle>
            </Card>
          </Box>
          <Box flex={1} mx={1}>
            <Card className={cx(classes.item)}>
              <Title>Unassigned</Title>
              <Number size="medium">{stats.unassigned}</Number>
              <Subtitle>Unassigned Onfleet tasks</Subtitle>
            </Card>
          </Box>
          <Box flex={1} mx={1}>
            <Card className={cx(classes.item)}>
              <Title>Waiting</Title>
              <Number size="medium">{stats.waiting}</Number>
              <Subtitle>Orders waiting to be picked</Subtitle>
            </Card>
          </Box>
          <Box flex={1} mx={1}>
            <Card className={cx(classes.item)}>
              <Title>Picking</Title>
              <Number size="medium">{stats.picking}</Number>
              <Subtitle>Orders in picking</Subtitle>
            </Card>
          </Box>
          <Box flex={1} mx={1}>
            <Card className={cx(classes.item)}>
              <Title>Assigned</Title>
              <Number size="medium">{stats.assigned}</Number>
              <Subtitle>Assigned Onfleet tasks</Subtitle>
            </Card>
          </Box>
          <Box flex={1} mx={1}>
            <Card className={cx(classes.item)}>
              <Title>In Delivery</Title>
              <Number size="medium">{stats.active}</Number>
              <Subtitle>Active OnFleet tasks</Subtitle>
            </Card>
          </Box>
        </Box>
      </Box>

      <Box m={1} flex={0.5} display="flex" className={classes.timersTile}>
        <Box m={-1} flex="1" display="flex">
          <Box height="1" flex="1" display="flex" flexDirection="column">
            <Box textAlign="center" m={1} flex="1">
              <Card className={cx(classes.time)}>
                <Number size="small">
                  <span
                    className={cx({
                      [classes.warningText]:
                        stats.deliveredToPromisePercent <
                        DELIVERED_TO_PROMISE_WARNING,
                      [classes.errorText]:
                        stats.deliveredToPromisePercent <
                        DELIVERED_TO_PROMISE_DANGER,
                    })}
                  >
                    {stats.deliveredToPromisePercent.toFixed(0)}%
                  </span>
                </Number>
                <Subtitle>Delivered to promise </Subtitle>
                <Description>Today (from 00:00)</Description>
              </Card>
            </Box>

            <Box textAlign="center" m={1} flex="1">
              <Card className={cx(classes.time)}>
                <Time size="tiny">
                  <FormatDuration
                    from={NECESSARY_OFFSET}
                    to={avgCompletionTime + NECESSARY_OFFSET}
                    warnAfter={DELIVERY_10AVG_WARNING}
                    dangerAfter={DELIVERY_10AVG_DANGER}
                  />
                </Time>
                <Subtitle>Delivery Avg Duration</Subtitle>
                <Description>Last 10 orders</Description>
              </Card>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box m={1} flex={0.5} display="flex" className={classes.timersTile}>
        <Box m={-1} flex="1" display="flex">
          <Box height="1" flex="1" display="flex" flexDirection="column">
            <Box textAlign="center" m={1} flex="1">
              <Card className={cx(classes.time)}>
                <Number size="small">
                  <span
                    className={cx({
                      [classes.warningText]:
                        stats.deliveredOnTimePercent <
                        DELIVERED_ON_TIME_WARNING,
                      [classes.errorText]:
                        stats.deliveredOnTimePercent <=
                        DELIVERED_ON_TIME_DANGER,
                    })}
                  >
                    {stats.deliveredOnTimePercent.toFixed(0)}%
                  </span>
                </Number>
                <Subtitle>Delivered on time</Subtitle>
                <Description>Today (from 00:00)</Description>
              </Card>
            </Box>

            <Box textAlign="center" m={1} flex="1">
              <Card className={cx(classes.time)}>
                <Number size="small">{stats.ridersOnline}</Number>
                <Subtitle>Riders online</Subtitle>
              </Card>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
