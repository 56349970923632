import React from "react";
import { Duration } from "luxon";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import { makeStyles, Theme } from "@material-ui/core";
import cx from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: ".95em",
  },
  warn: {
    color: orange[500],
    fontWeight: 600,
  },
  danger: {
    color: red[500],
    fontWeight: 700,
  },
}));

const FormatDuration = React.memo(
  ({
    from,
    to,
    warnAfter,
    dangerAfter,
    dontshow,
  }: {
    from?: number;
    to: number;
    warnAfter?: number;
    dangerAfter?: number;
    dontshow?: boolean;
  }) => {
    const classes = useStyles();
    if (dontshow || !from || !to || to < from) {
      return <span>-</span>;
    }

    const value = to - from;
    const duration = Duration.fromMillis(value).toFormat("hh:mm:ss");

    return (
      <span
        className={cx(classes.root, {
          [classes.warn]: warnAfter && value > warnAfter,
          [classes.danger]: dangerAfter && value > dangerAfter,
        })}
      >
        {duration}
      </span>
    );
  },
);

export default FormatDuration;
