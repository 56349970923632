import grey from "@material-ui/core/colors/grey";
import {
  createTheme,
  responsiveFontSizes,
  alpha,
} from "@material-ui/core/styles";

// https://material-ui.com/customization/themes/#themes

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    status: {
      danger: {
        primary: React.CSSProperties["color"];
        secondary: React.CSSProperties["color"];
      };
      warning: {
        primary: React.CSSProperties["color"];
        secondary: React.CSSProperties["color"];
      };
      idle: {
        primary: React.CSSProperties["color"];
        secondary: React.CSSProperties["color"];
      };
    };
  }
  interface ThemeOptions {
    status: {
      danger: {
        primary: React.CSSProperties["color"];
        secondary: React.CSSProperties["color"];
      };
      warning: {
        primary: React.CSSProperties["color"];
        secondary: React.CSSProperties["color"];
      };
      idle: {
        primary: React.CSSProperties["color"];
        secondary: React.CSSProperties["color"];
      };
    };
  }
}

const theme = createTheme({
  status: {
    danger: { primary: "#B5233E", secondary: "#E63555" },
    warning: { primary: "#C86102", secondary: "#FF891C" },
    idle: { primary: "#148233", secondary: "#05CD85" },
  },
  palette: {
    background: { default: "#212331", paper: "#2B2D3D" },
    primary: { main: "#02BAFF", contrastText: "#FFFFFF" },
    secondary: { main: "#02BAFF", contrastText: "#FFFFFF" },
    text: { primary: "#FFFFFF" },
    divider: "rgba(255,255,255,.1)",
  },

  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: grey[50],
      },
    },
    MuiButton: {
      text: { textTransform: "none", fontWeight: 600 },
      textSizeSmall: { textTransform: "uppercase" },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: alpha("#404258", 0.9),
        fontSize: "1em",
      },
      arrow: { color: alpha("#404258", 0.9) },
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
    },
    MuiTableRow: {
      root: {
        "&$selected": {
          backgroundColor: "#404258",
          opacity: 1,
          "&:hover": { backgroundColor: "#404258" },
        },

        "&$hover": {
          "&:hover": {
            backgroundColor: "rgba(0,0,0,.1)",
          },
        },
      },
    },
    MuiChip: {
      root: {
        height: 28,
      },
      label: {
        fontWeight: 700,

        fontSize: "1.1em",
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    h5: {
      fontWeight: 700,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default responsiveFontSizes(theme);
