import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  first: {
    marginRight: ".2em",
    opacity: 0.7,
  },
});

const OrderNumber = ({ value }: { value?: number }) => {
  const styles = useStyles();
  const numStr = value + "";
  const [first, last] = [
    numStr.substr(0, numStr.length - 3),
    numStr.substr(numStr.length - 3),
  ];

  return (
    <span>
      <small className={styles.first}>{first}</small>
      <strong>{last}</strong>
    </span>
  );
};

export default OrderNumber;
