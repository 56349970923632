import { FC, useMemo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Card, cardStyles } from "../Card";
import { Description, Subtitle } from "../Text";
import cx from "classnames";
import { WarehouseStats } from "@quickcommerceltd/zappboard";
import { env } from "../../config";
import FormatDuration from "../FormatDuration";
import { ActiveBadgePercentage } from "./ActiveBadgePercentage";
import { ActiveNumber } from "./ActiveNumber";
import { ActiveFormatDuration } from "./ActiveFormatDuration";
import { DateTime } from "luxon";

const PERCENT = 100;
const NECESSARY_OFFSET = 1; //Needed to use the FormatDuration component
export interface RevenueWarehouseStats extends WarehouseStats {
  revenue: RevenueStats;
  workload: WorkloadStats;
}

interface RevenueStats {
  today: number;
  todayTotalGrowthNum: number;
  todayExpectedTotal: number;
  todayExpectedTotalGrowthNum: number;
  lastWeekSameDayTotal: number;
  lastWeekSameDaySameTime: number;
}

interface WorkloadStats {
  timeFor100OrdersTodayMs: number;
  timeFor100OrdersLastWeekSameDayMs: number;
  timeFor100OrdersTodayLastWeekSameDayDifferenceMs: number;
  timeFor100OrdersLastWeekSameDayPercent: number;
  globalTimeFor100OrdersTodayMs: number;
  globalTimeFor100OrdersLastWeekSameDayMs: number;
  globalTimeFor100OrdersTodayLastWeekSameDayDifferenceMs: number;
  globalTimeFor100OrdersLastWeekSameDayPercent: number;
}

const useStyles = makeStyles(() => ({
  durationText: {
    fontSize: "1.8rem",
  },
}));

export const RevenueAndWorkloadStats: FC<{
  warehouses?: Partial<RevenueWarehouseStats>[];
}> = ({ warehouses }) => {
  const currency = ["stg", "dev", "gb"].includes(env) ? "£" : "€";
  const classes = { ...cardStyles(), ...useStyles() };
  const stats = useMemo(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("warehouses", warehouses);
    }

    const revenueAccumulated = {
      today: 0,
      todayTotalGrowthNum: 0,
      allTodayTotalGrowthPercent: 0,
      todayExpectedTotal: 0,
      todayExpectedTotalGrowthNum: 0,
      allTodayExpectedTotalGrowthPercent: 0,
      lastWeekSameDayTotal: 0,
      lastWeekSameDaySameTime: 0,
    };

    const workloadAccumulated = {
      timeFor100OrdersTodayMs: 0,
      timeFor100OrdersLastWeekSameDayMs: 0,
      timeFor100OrdersTodayLastWeekSameDayDifferenceMs: 0,
      timeFor100OrdersLastWeekSameDayPercent: 0,
    };

    warehouses?.forEach((w) => {
      revenueAccumulated.today += w.revenue?.today ?? 0;
      revenueAccumulated.todayTotalGrowthNum +=
        w.revenue?.todayTotalGrowthNum ?? 0;
      revenueAccumulated.todayExpectedTotal +=
        w.revenue?.todayExpectedTotal ?? 0;
      revenueAccumulated.todayExpectedTotalGrowthNum +=
        w.revenue?.todayExpectedTotalGrowthNum ?? 0;
      revenueAccumulated.lastWeekSameDayTotal +=
        w.revenue?.lastWeekSameDayTotal ?? 0;
      revenueAccumulated.lastWeekSameDaySameTime +=
        w.revenue?.lastWeekSameDaySameTime ?? 0;
    });

    const calculatePercent = (curr: number, prev: number): number => {
      if (!prev) {
        return 0;
      }

      return (curr / prev - 1) * PERCENT;
    };

    if (warehouses && warehouses.length === 1) {
      workloadAccumulated.timeFor100OrdersTodayMs =
        warehouses[0].workload?.timeFor100OrdersTodayMs ?? 0;
      workloadAccumulated.timeFor100OrdersLastWeekSameDayMs =
        warehouses[0].workload?.timeFor100OrdersLastWeekSameDayMs ?? 0;
      workloadAccumulated.timeFor100OrdersTodayLastWeekSameDayDifferenceMs =
        warehouses[0].workload
          ?.timeFor100OrdersTodayLastWeekSameDayDifferenceMs ?? 0;
    }

    if (warehouses && warehouses.length > 1) {
      workloadAccumulated.timeFor100OrdersTodayMs =
        warehouses[0].workload?.globalTimeFor100OrdersTodayMs ?? 0;
      workloadAccumulated.timeFor100OrdersLastWeekSameDayMs =
        warehouses[0].workload?.globalTimeFor100OrdersLastWeekSameDayMs ?? 0;
      workloadAccumulated.timeFor100OrdersTodayLastWeekSameDayDifferenceMs =
        warehouses[0].workload
          ?.globalTimeFor100OrdersTodayLastWeekSameDayDifferenceMs ?? 0;
    }

    workloadAccumulated.timeFor100OrdersLastWeekSameDayPercent =
      calculatePercent(
        workloadAccumulated.timeFor100OrdersTodayMs,
        workloadAccumulated.timeFor100OrdersLastWeekSameDayMs,
      );

    revenueAccumulated.allTodayTotalGrowthPercent = calculatePercent(
      revenueAccumulated.today,
      revenueAccumulated.lastWeekSameDaySameTime,
    );
    revenueAccumulated.allTodayExpectedTotalGrowthPercent = calculatePercent(
      revenueAccumulated.todayExpectedTotal,
      revenueAccumulated.lastWeekSameDayTotal,
    );

    return {
      ...revenueAccumulated,
      ...workloadAccumulated,
    };
  }, [warehouses]);

  const convertToCurrency = (value: number = 0): string => {
    return `${(value / 1000).toFixed(2)}K ${currency}`;
  };

  const now = DateTime.now();
  const weekday = now.toFormat("MM/dd/yy");
  const lastWeekday = now.minus({ day: 7 }).toFormat("MM/dd/yy");
  const time = now.toFormat("HH:mm");

  return (
    <Box display="flex" gridGap={16} className={classes.tiles} fontSize=".9em">
      <Box m={-1} display="flex" flex={1} className={classes.head}>
        <Box m={1} flex={1}>
          <Card className={cx(classes.card)}>
            <Subtitle>
              Revenue [{weekday} {time}]
            </Subtitle>
            <Box
              m={1}
              mt={2}
              gridGap={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ActiveBadgePercentage
                value={stats.todayTotalGrowthNum / 1000}
                suffix={`K ${currency}`}
              />
              <ActiveNumber
                size="small"
                children={convertToCurrency(stats.today)}
              />
              <ActiveBadgePercentage value={stats.allTodayTotalGrowthPercent} />
            </Box>
            <Box>
              <Subtitle>
                {convertToCurrency(stats.lastWeekSameDaySameTime)}
              </Subtitle>
              <Description>
                Revenue [{lastWeekday} {time}]
              </Description>
            </Box>
          </Card>
        </Box>
      </Box>

      <Box m={-1} display="flex" flex={1} className={classes.head}>
        <Box m={1} flex={1}>
          <Card className={cx(classes.card)}>
            <Subtitle>Projected Revenue [{weekday}]</Subtitle>
            <Box
              m={1}
              mt={2}
              gridGap={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ActiveBadgePercentage
                value={stats.todayExpectedTotalGrowthNum / 1000}
                suffix={`K ${currency}`}
              />
              <ActiveNumber
                size="small"
                children={convertToCurrency(stats.todayExpectedTotal)}
              />
              <ActiveBadgePercentage
                value={stats.allTodayExpectedTotalGrowthPercent}
              />
            </Box>
            <Box>
              <Subtitle>
                {convertToCurrency(stats.lastWeekSameDayTotal)}
              </Subtitle>
              <Description>Revenue [{lastWeekday}]</Description>
            </Box>
          </Card>
        </Box>
      </Box>

      <Box m={-1} display="flex" flex={1} className={classes.head}>
        <Box m={1} flex={1}>
          <Card className={cx(classes.card)}>
            <Subtitle>
              Time for 100 orders [{weekday} {time}]
            </Subtitle>
            <Box
              m={1}
              mt={1.5}
              mb={0.5}
              gridGap={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ActiveBadgePercentage
                value={stats.timeFor100OrdersTodayLastWeekSameDayDifferenceMs}
                inverted={true}
                duration={true}
              />
              <Box className={cx(classes.durationText)}>
                <ActiveFormatDuration value={stats.timeFor100OrdersTodayMs} />
              </Box>
              <ActiveBadgePercentage
                value={stats.timeFor100OrdersLastWeekSameDayPercent}
                inverted={true}
              />
            </Box>
            <Box>
              <Subtitle>
                <FormatDuration
                  from={NECESSARY_OFFSET}
                  to={
                    stats.timeFor100OrdersLastWeekSameDayMs + NECESSARY_OFFSET
                  }
                />
              </Subtitle>
              <Description>
                Time for 100 orders [{lastWeekday} {time}]
              </Description>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
