import { Box, Card } from "@material-ui/core";
import { cardStyles } from "../Card";
import cx from "classnames";
import { FC } from "react";
import { WarehouseStats } from "@quickcommerceltd/zappboard";

interface Props {
  stats?: WarehouseStats;
}
const WarehouseCard: FC<Props> = ({ stats }) => {
  const classes = cardStyles();
  return (
    <Card className={cx(classes.card, classes.none, classes.logo)}>
      <Box textAlign="center">
        <img
          src="/zapp-logo.svg"
          width={140}
          alt="Zapp"
          className={classes.logoSvg}
        />
      </Box>
      <Box mt={1}>
        <b>
          <span className={classes.shortName}>{stats?.shortName}</span>
          {" · "}
          {stats?.pickUpAddress?.city}
        </b>
      </Box>
      <Box>{stats?.pickUpAddress?.address1}</Box>
    </Card>
  );
};

export default WarehouseCard;
