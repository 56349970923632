import { TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useCustomerSupportModalStyles } from "../CustomerSupportModal.style";
import { SubmitButton } from ".";
import {
  SupportInquiry,
  SupportInquiryCode,
} from "@quickcommerceltd/zappboard";
import { FC, useState } from "react";

interface Props {
  onButtonClick: () => void;
  loading: boolean;
  setRequest: (inquiry: Partial<SupportInquiry>) => void;
}

export const CustomerSupportOther: FC<Props> = ({
  onButtonClick,
  loading,
  setRequest,
}) => {
  const classes = useCustomerSupportModalStyles();
  const [message, setMessage] = useState(undefined);

  const handleMessageChange = (event: any) => {
    let text = event.target.value;
    setMessage(text);
    setRequest({
      code: SupportInquiryCode.OTHER,
      delayReason: undefined,
      cancelReason: undefined,
      message: text,
      outOfStockItems: undefined,
    });
  };

  return (
    <Box>
      <TextField
        id="message"
        label="Message"
        variant="outlined"
        value={message}
        onChange={handleMessageChange}
        multiline={true}
        minRows={4}
        maxRows={6}
        className={classes.formControl}
      />

      <SubmitButton
        onButtonClick={onButtonClick}
        loading={loading}
        disabled={!message}
      />
    </Box>
  );
};
