import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import ChatIcon from "@material-ui/icons/Chat";
import Tooltip from "@material-ui/core/Tooltip";
import MuiRating from "@material-ui/lab/Rating";

const useStyles = makeStyles({
  customerRatingNote: {
    marginLeft: "4px",
    fontSize: (props: any) => (props.size === "small" ? "16px" : "20px"),
    color: "darkgrey",
    cursor: "pointer",
  },
});

interface Props {
  rating: number;
  note?: string;
  size?: "small" | "medium" | "large";
}

export const Rating: FC<Props> = ({ rating, note = "", size = "medium" }) => {
  const styles = useStyles({ size });

  return (
    <Tooltip title={note}>
      <div>
        <MuiRating name="customer-rating" value={rating} size={size} readOnly />
        {note && <ChatIcon className={styles.customerRatingNote} />}
      </div>
    </Tooltip>
  );
};
