import firebase from "firebase/app";
import "firebase/functions";
import "firebase/remote-config";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  region: process.env.REACT_APP_FIREBASE_APP_REGION,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID,
};

const app = firebase.initializeApp(firebaseConfig);
const functions = app.functions(firebaseConfig.region);

//remote conf
const remoteConfig = firebase.remoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 600000; // 10 minutes

export { firebase, functions, remoteConfig };
