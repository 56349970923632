import MuiAlert from "@material-ui/lab/Alert";
import MuiSnackbar from "@material-ui/core/Snackbar";
import { createStyles, withStyles } from '@material-ui/styles';

export const Snackbar = withStyles(() =>
  createStyles({
    root: {
      width: "100%",
      bottom: 0,
    },
  }),
)(MuiSnackbar);

export const Alert = withStyles(() =>
  createStyles({
    root: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 0,
      fontSize: "20px",
      fontWeight: "bold",
    },
    icon: {
      fontSize: "28px",
    },
  }),
)(MuiAlert);
