import { ReactNode, useEffect, useState } from "react";
import {
  AppBar as MaterialAppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  useScrollTrigger,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import routes from "../pages/routes";
import useFullscreen from "../utils/hooks/useFullscreen";
import { UserDropdown } from "./UserDropdown/UserDropdown";
import { remoteConfig } from "../firebase";
import Snowfall from "react-snowfall";

type Props = {
  title?: string;
  backTo?: string;
  actions?: ReactNode;
  searchbar?: ReactNode;
};

const AppBar = (props: Props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 40,
  });

  const [isChristmas, setIsChristmas] = useState(false);
  const [isFullscreen] = useFullscreen();

  useEffect(() => {
    remoteConfig.fetchAndActivate();
    const enabled = remoteConfig.getBoolean("isChristmas");
    setIsChristmas(enabled);
  }, []);

  if (isFullscreen) {
    return null;
  }

  const logoUrl = isChristmas
    ? "/zappboard-logo-xmas.svg"
    : "/zappboard-logo.svg";

  return (
    <MaterialAppBar
      elevation={trigger ? 4 : 0}
      position="sticky"
      style={{ background: "#212331" }}
    >
      {isChristmas && (
        <Snowfall snowflakeCount={40} speed={[0.1, 0.5]} wind={[0.1, 0.5]} />
      )}
      <Toolbar>
        {props.backTo && (
          <IconButton
            component={Link}
            to={props.backTo}
            color="inherit"
            edge="start"
          >
            <ArrowBackIcon titleAccess="Navigate Back" />
          </IconButton>
        )}

        <Link to={routes.home}>
          <img
            src={logoUrl}
            alt="Zappboard"
            width={180}
            style={{ position: "relative", top: 2, display: "block" }}
          />
        </Link>

        <Box ml={3} flex={!props.searchbar ? "auto" : "none"}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {props.title}
          </Typography>
        </Box>

        {props.searchbar && (
          <Box ml={10} mr={8} flex="auto">
            {props.searchbar}
          </Box>
        )}

        {props.actions && <Box mr={2}>{props.actions}</Box>}

        <UserDropdown />
      </Toolbar>
    </MaterialAppBar>
  );
};

export default AppBar;
