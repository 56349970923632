import { makeStyles } from "@material-ui/core";

export const usePickingDeviceAssignmentModalStyles = makeStyles((theme) => ({
  paper: {
    width: "min(100%, 600px)",
    padding: theme.spacing(0, 3, 3),
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  closeIcon: {
    position: "absolute",
    top: "12px",
    right: "12px",
    fontSize: "2rem",
    cursor: "pointer",
  },
  formControl: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiFormLabel-root": {
        color: "#DADADA",
    },
    "& .MuiInputBase-input": {
        color: "white",
    },
  },
  select: {
    margin: "16px 0",
  },
  button: {
    width: "100%",
    height: "36px",
  },
}));
