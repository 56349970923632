import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {
  Order,
  OutOfStockItem,
  SupportInquiry,
  SupportInquiryCode,
} from "@quickcommerceltd/zappboard";
import { FC, useCallback, useState } from "react";
import { SubmitButton } from ".";
import { OrderLinesOOS } from ".";

interface Props {
  onButtonClick: () => void;
  loading: boolean;
  order: Order;
  setRequest: (inquiry: Partial<SupportInquiry>) => void;
}

export const CustomerSupportOOS: FC<Props> = ({
  onButtonClick,
  loading,
  order,
  setRequest,
}) => {
  const [itemsOOS, setItemsOOS] = useState<OutOfStockItem[] | undefined>(
    undefined,
  );

  const handleUpdate = useCallback(
    (value) => {
      setItemsOOS(value);
      setRequest({
        code: SupportInquiryCode.ITEM_OOS,
        delayReason: undefined,
        cancelReason: undefined,
        message: undefined,
        outOfStockItems: value,
      });
    },
    [setRequest],
  );

  return (
    <Box>
      <Typography variant="h5" style={{ textAlign: "center" }}>
        Which Item is OOS?
      </Typography>
      <Typography variant="body2" style={{ textAlign: "center" }}>
        You can set the missing amout for multiple items
      </Typography>
      <Box ml={-1}>
        <OrderLinesOOS
          order={order}
          setItemsOOS={handleUpdate}
          itemsOOS={itemsOOS}
        />
      </Box>
      <SubmitButton
        onButtonClick={onButtonClick}
        loading={loading}
        disabled={!itemsOOS}
      />
    </Box>
  );
};
