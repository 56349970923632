import { firebase } from "./firebase";
import "firebase/firestore";
import CustomFirestore from "../types/CustomFirestore";

const firestore = firebase.firestore() as CustomFirestore;

window.addEventListener("offline", () => firestore.disableNetwork());
window.addEventListener("online", () => firestore.enableNetwork());

export default firestore;
