export enum OrderEventsEnum {
  // from Saleor-Dashboard
  CREDIT_CREATED = "CREDIT_CREATED",
  PACKED = "PACKED",
  DRAFT_CREATED = "DRAFT_CREATED",
  DRAFT_ADDED_PRODUCTS = "DRAFT_ADDED_PRODUCTS",
  DRAFT_REMOVED_PRODUCTS = "DRAFT_REMOVED_PRODUCTS",
  PLACED = "PLACED",
  PLACED_FROM_DRAFT = "PLACED_FROM_DRAFT",
  OVERSOLD_ITEMS = "OVERSOLD_ITEMS",
  TICKET_CREATED = "TICKET_CREATED",
  CANCELED = "CANCELED",
  LINES_CANCELLED = "LINES_CANCELLED",
  ORDER_MARKED_AS_PAID = "ORDER_MARKED_AS_PAID",
  ORDER_FULLY_PAID = "ORDER_FULLY_PAID",
  UPDATED_ADDRESS = "UPDATED_ADDRESS",
  EMAIL_SENT = "EMAIL_SENT",
  PAYMENT_AUTHORIZED = "PAYMENT_AUTHORIZED",
  PAYMENT_CAPTURED = "PAYMENT_CAPTURED",
  EXTERNAL_SERVICE_NOTIFICATION = "EXTERNAL_SERVICE_NOTIFICATION",
  PAYMENT_REFUNDED = "PAYMENT_REFUNDED",
  PAYMENT_VOIDED = "PAYMENT_VOIDED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  INVOICE_REQUESTED = "INVOICE_REQUESTED",
  INVOICE_GENERATED = "INVOICE_GENERATED",
  INVOICE_UPDATED = "INVOICE_UPDATED",
  INVOICE_SENT = "INVOICE_SENT",
  FULFILLMENT_CANCELED = "FULFILLMENT_CANCELED",
  FULFILLMENT_RESTOCKED_ITEMS = "FULFILLMENT_RESTOCKED_ITEMS",
  FULFILLMENT_FULFILLED_ITEMS = "FULFILLMENT_FULFILLED_ITEMS",
  TRACKING_UPDATED = "TRACKING_UPDATED",
  NOTE_ADDED = "NOTE_ADDED",
  OTHER = "OTHER",
}
