import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { default as MuiTextField } from "@material-ui/core/TextField";

export const useSearchbarStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    gap: "12px",
    width: "100%",
    maxWidth: "800px",
    margin: "0 auto",
  },
}));

export const TextField = withStyles(() =>
  createStyles({
    root: {
      width: "100%",

      "& .MuiOutlinedInput-root": {
        borderRadius: "10px",

        // outline
        "& fieldset": {
          borderColor: "#AFB3D8",
        },
        "&.Mui-focused fieldset": {
          border: "2px solid white",
        },

        // icon in input
        "& .MuiIconButton-label": {
          color: "#DADADA",
        },
      },

      "& .MuiOutlinedInput-adornedStart": {
        padding: "0",
      },

      "& .MuiOutlinedInput-adornedEnd": {
        padding: "0",
      },

      // input
      "& .MuiOutlinedInput-input": {
        color: "white",
        fontSize: "14px",

        "&::placeholder": {
          color: "#AFB3D8",
          opacity: 1,
        },
      },
    },
  }),
)(MuiTextField);
