import {
  Avatar,
  Typography,
  TableContainer,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Order, Line, OutOfStockItem } from "@quickcommerceltd/zappboard";
import { FC } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Table";
import { useCustomerSupportModalStyles } from "../CustomerSupportModal.style";

interface Props {
  order: Order;
  setItemsOOS: (inquiry: OutOfStockItem[] | undefined) => void;
  itemsOOS: OutOfStockItem[] | undefined;
}

export const OrderLinesOOS: FC<Props> = ({ order, setItemsOOS, itemsOOS }) => {
  const classes = useCustomerSupportModalStyles();

  const handleQuantityChange = (changedItem: Line, event: any) => {
    if (isNaN(event.target.value)) {
      event.target.value = 0;
      return;
    }

    let quantityInput = Number(event.target.value);
    if (quantityInput < 0) {
      quantityInput = 0;
      event.target.value = 0;
    }
    if (quantityInput > changedItem.quantity) {
      quantityInput = changedItem.quantity;
      event.target.value = changedItem.quantity;
    }

    const isItemsOOS = itemsOOS && itemsOOS.length > 0;
    let newItemsOOS;

    if (!isItemsOOS && quantityInput === 0) return;
    if (!isItemsOOS && quantityInput !== 0) {
      setItemsOOS([
        {
          sku: changedItem.productSku,
          title: changedItem.productName,
          missingQuantity: quantityInput,
          orderedQuantity: changedItem.quantity,
        },
      ]);
      return;
    }

    if (isItemsOOS && quantityInput === 0) {
      newItemsOOS = itemsOOS.filter(
        (item) => item.sku !== changedItem.productSku,
      );
      if (newItemsOOS.length > 0) {
        setItemsOOS(newItemsOOS);
      } else {
        setItemsOOS(undefined);
      }
      return;
    }

    if (isItemsOOS && quantityInput > 0) {
      const indexOfItem = itemsOOS.findIndex(
        (item) => item.sku === changedItem.productSku,
      );
      newItemsOOS = [...itemsOOS];
      if (indexOfItem > -1) {
        newItemsOOS[indexOfItem].missingQuantity = quantityInput;
        setItemsOOS(newItemsOOS);
        return;
      }
      newItemsOOS.push({
        sku: changedItem.productSku,
        title: changedItem.productName,
        missingQuantity: quantityInput,
        orderedQuantity: changedItem.quantity,
      });
      setItemsOOS(newItemsOOS);
    }
  };

  return (
    <>
      <TableContainer className={classes.tableContainerOOS}>
        <Table aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Product-Name</TableCell>
              <TableCell align="center">SKU</TableCell>
              <TableCell align="center">QTY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.lines?.map((line, index) => (
              <TableRow
                key={index}
                hover
                className={
                  itemsOOS?.find((l) => l.sku === line.productSku)
                    ? classes.markItemLineOOS
                    : undefined
                }
              >
                <TableCell className={classes.productImageCell}>
                  <Avatar
                    variant="rounded"
                    className={classes.productImage}
                    src={
                      line.productImage?.thumbnail || line.productImage?.image
                    }
                  >
                    {false}
                  </Avatar>
                </TableCell>
                <TableCell>{line.productName}</TableCell>
                <TableCell>
                  <Typography noWrap variant="body2" align="center">
                    {line.productSku}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <TextField
                    onChange={(event) => handleQuantityChange(line, event)}
                    className={classes.inputOOS}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          /{line.quantity}
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
