import { useEffect, FC, useState } from "react";
import useSound from "use-sound";

export const SoundEffect: FC<{ value: number }> = ({ value }) => {
  const [play] = useSound("/alert.mp3", {
    volume: 0.6,
  });

  const [prev, setPrev] = useState(0);

  useEffect(() => {
    if (value === prev) {
      return;
    }
    if (value > prev) {
      try {
        play();
        console.log("play sound");
      } catch (e) {}
    }

    setPrev(value);
  }, [value, prev, play]);

  return null;
};
