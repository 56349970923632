import { Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  FulfillmentStatus,
  Order,
  ShippingMethod,
  TaskState,
} from "@quickcommerceltd/zappboard";
import cx from "classnames";
import { DateTime } from "luxon";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { formatAddress } from "../../utils/formatContacts";
import FormatDuration from "../FormatDuration";
import OrderNumber from "../OrderNumber";
import { Rating } from "../Rating/Rating";
import {
  HeaderRow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../Table";

const MINUTE_IN_MS = 60000;
const NECESSARY_OFFSET = 1; //Needed to use the FormatDuration component

const compareStatus = (a: Order, b: Order) => {
  const isUnfulfilled = (status: FulfillmentStatus) =>
    status === FulfillmentStatus.UNFULFILLED;
  return (
    Number(isUnfulfilled(b.fulfillmentStatus)) -
      Number(isUnfulfilled(a.fulfillmentStatus)) || b.createdAt - a.createdAt
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  done: { opacity: 0.6 },
  selected: {
    "&:hover": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
  },
  unassigned: {
    backgroundColor: theme.status.danger.primary,
    "&:hover": {
      backgroundColor: `#8c1b30 !important`,
    },
  },
  unassignedSelected: {
    backgroundColor: `${theme.status.danger.secondary} !important`,
  },
  new_customer_badge: {
    marginRight: "12px",
    padding: "2px 4px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    fontSize: "10px",
    fontWeight: "bold",
  },
}));

const calculateToTimestamp = (
  order: Order,
  statusTimestamp: number | undefined,
  now: number,
): number => {
  const isCompleted =
    order.fulfillmentStatus === FulfillmentStatus.FULFILLED ||
    order.fulfillmentStatus === FulfillmentStatus.CANCELLED;

  if (isCompleted && !statusTimestamp) {
    return 0; // no time shown due to missing timestamp
  }

  return Math.min(now, statusTimestamp || order.deliveredAt || now);
};

export const OrdersList: FC<{
  orders: Order[];
  selectedOrderId?: string;
  interactive?: boolean;
}> = ({ orders, selectedOrderId, interactive }) => {
  const styles = useStyles();
  const [now, setNow] = useState(new Date().getTime());

  useEffect(() => {
    const timer = setInterval(() => setNow(new Date().getTime()), 1000);

    return () => clearInterval(timer);
  }, []);

  const history = useHistory();

  const sortedOrders = useMemo(() => {
    return orders.sort(compareStatus);
  }, [orders]);

  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="customized table">
          <TableHead>
            <HeaderRow>
              <TableCell align="right">#</TableCell>
              <TableCell align="center">Created</TableCell>
              <TableCell align="center">Fulfillment</TableCell>
              <TableCell align="center">Delivery</TableCell>
              <TableCell align="center">Picking</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Picker</TableCell>
              <TableCell>Rider</TableCell>
              <TableCell align="center">Waiting</TableCell>
              <TableCell align="center">Picking</TableCell>
              <TableCell align="center">Racktime</TableCell>
              <TableCell align="center">Riding</TableCell>
              <TableCell align="center">Delivery</TableCell>
              <TableCell align="center">Promised</TableCell>
            </HeaderRow>
          </TableHead>
          <TableBody>
            {sortedOrders.map((order) => (
              <TableRow
                hover={!!interactive}
                key={order.id}
                selected={selectedOrderId === order.id}
                onClick={() => {
                  if (interactive) {
                    history.replace(`?orderId=${order.id}`);
                  }
                }}
                className={cx({
                  [styles.done]: [
                    FulfillmentStatus.FULFILLED,
                    FulfillmentStatus.CANCELLED,
                  ].includes(order.fulfillmentStatus),
                  [styles.unassigned]:
                    order.deliveryStatus === TaskState.UNASSIGNED &&
                    order.fulfillmentStatus !== FulfillmentStatus.CANCELLED,
                  [styles.unassignedSelected]:
                    order.deliveryStatus === TaskState.UNASSIGNED &&
                    order.fulfillmentStatus !== FulfillmentStatus.CANCELLED &&
                    selectedOrderId === order.id,
                  [styles.selected]: selectedOrderId === order.id,
                })}
              >
                <TableCell scope="row" align="right">
                  {order.isNewCustomer && (
                    <span className={styles.new_customer_badge}>new</span>
                  )}
                  <OrderNumber value={order.number} />
                </TableCell>
                <TableCell scope="row" align="center">
                  <Tooltip
                    title={
                      order.createdAt
                        ? DateTime.fromMillis(order.createdAt).toLocaleString(
                            DateTime.DATETIME_FULL_WITH_SECONDS,
                          )
                        : "-"
                    }
                  >
                    <div>
                      {order.createdAt
                        ? DateTime.fromMillis(order.createdAt).toFormat("HH:mm")
                        : "—"}
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell
                  scope="row"
                  align="center"
                  title={order.fulfillmentStatus}
                >
                  {order.fulfillmentStatus}
                </TableCell>
                <TableCell scope="row" align="center">
                  {!order.deliveryStatus ? (
                    order.shippingMethod
                  ) : order.deliveryStatus === TaskState.COMPLETED &&
                    !!order.customerRating?.rating ? (
                    <Rating
                      rating={order.customerRating.rating}
                      note={order.customerRating?.note}
                      size="small"
                    />
                  ) : (
                    order.deliveryStatus
                  )}
                </TableCell>
                <TableCell
                  scope="row"
                  align="center"
                  title={order.pickingStatus}
                >
                  {order.pickingStatus || "—"}
                </TableCell>

                <TableCell>{formatAddress(order.shippingAddress)}</TableCell>
                <TableCell>{order.picker?.name || "—"}</TableCell>
                <TableCell>{order.rider?.name || "—"}</TableCell>
                <TableCell align="center">
                  <FormatDuration
                    from={order.createdAt}
                    to={calculateToTimestamp(
                      order,
                      order.pickingStartedAt,
                      now,
                    )}
                    warnAfter={20000}
                    dangerAfter={30000}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormatDuration
                    from={order.pickingStartedAt}
                    to={calculateToTimestamp(order, order.pickingEndedAt, now)}
                    warnAfter={4.5 * MINUTE_IN_MS}
                    dangerAfter={5 * MINUTE_IN_MS}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormatDuration
                    dontshow={order.shippingMethod === ShippingMethod.PICKUP}
                    from={order.pickingEndedAt}
                    to={calculateToTimestamp(
                      order,
                      order.deliveryStartedAt,
                      now,
                    )}
                    warnAfter={1.5 * MINUTE_IN_MS}
                    dangerAfter={2 * MINUTE_IN_MS}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormatDuration
                    dontshow={order.shippingMethod === ShippingMethod.PICKUP}
                    from={order.deliveryStartedAt}
                    to={calculateToTimestamp(order, order.deliveredAt, now)}
                    warnAfter={9 * MINUTE_IN_MS}
                    dangerAfter={10 * MINUTE_IN_MS}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormatDuration
                    dontshow={order.shippingMethod === ShippingMethod.PICKUP}
                    from={order.createdAt}
                    to={calculateToTimestamp(order, order.deliveredAt, now)}
                    dangerAfter={order.promisedDeliveryTime}
                  />
                </TableCell>
                <TableCell align="center">
                  {order.promisedDeliveryTimeV2 ? (
                    <FormatDuration
                      warnAfter={NECESSARY_OFFSET}
                      from={NECESSARY_OFFSET}
                      to={order.promisedDeliveryTimeV2 + NECESSARY_OFFSET}
                    />
                  ) : (
                    <FormatDuration
                      from={NECESSARY_OFFSET}
                      to={order.promisedDeliveryTime + NECESSARY_OFFSET}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
