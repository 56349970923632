import {
  createStyles,
  Paper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
  Theme,
  withStyles,
} from "@material-ui/core";

export const TableHead = MuiTableHead;
export const TableBody = MuiTableBody;
export const Table = MuiTable;

export const TableContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "rgba(255,255,255, 0.01)",
      border: `1px solid ${theme.palette.background.paper}`,
      borderRadius: "14px",
    },
  }),
)(({ children }) => (
  <MuiTableContainer component={Paper}>{children}</MuiTableContainer>
));

export const TableCell = withStyles(() =>
  createStyles({
    head: {
      padding: "8px 12px",
      fontSize: "1rem",
    },
    body: {
      fontSize: "1rem",
      padding: "12px 12px",
    },
    root: {
      borderBottom: "solid 1px #404258",
    },
  }),
)(MuiTableCell);

export const TableCellCompact = withStyles((theme: Theme) =>
  createStyles({
    head: {
      padding: "8px 6px",
      fontSize: "1rem",
      "&:first-child": { paddingLeft: 8 },
      [theme.breakpoints.up("xl")]: {
        padding: "8px 12px",
        "&:first-child": { paddingLeft: 12 },
      },
    },
    body: {
      fontSize: "1rem",
      padding: "12px 6px",
      "&:first-child": { paddingLeft: 8 },
      [theme.breakpoints.up("xl")]: {
        padding: 12,
        "&:first-child": { paddingLeft: 12 },
      },
    },
    root: {
      borderBottom: "solid 1px #404258",
    },
  }),
)(MuiTableCell);

export const TableRow = withStyles(() =>
  createStyles({
    hover: {
      cursor: "pointer",
    },
  }),
)(MuiTableRow);

export const HeaderRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#404258",
      "& th": {
        fontSize: "1em",
        lineHeight: "1.2em",
        fontWeight: 600,
      },
    },
  }),
)(MuiTableRow);

export const TableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        color: "#fff",
      },
    },
    icon: {
      marginLeft: -8,
      marginRight: -2,
      fill: "#fff",
      [theme.breakpoints.up("xl")]: {
        marginRight: 2,
      },
    },
  }),
)(MuiTableSortLabel);
