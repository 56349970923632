import { FC } from "react";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import { Alert, Snackbar } from "./OnlineStatusNotificationbar.style";

export const OnlineStatusNotificationbar: FC = () => {
  const isOnline = useOnlineStatus();

  return (
    <Snackbar
      open={!isOnline}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert severity="error" variant="filled">You are OFFLINE!</Alert>
    </Snackbar>
  )
};