import { FC, SyntheticEvent, useState } from "react";
import MoreIcon from "@material-ui/icons/MoreVert";
import {
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  withStyles,
  createStyles,
} from "@material-ui/core";
import { useRecoilState } from "recoil";
import { sessionState } from "../../state";
import routes from "../../pages/routes";
import { Link } from "react-router-dom";
import { remoteConfig } from "../../firebase";

const IconButton = withStyles(() =>
  createStyles({
    root: {
      padding: "4px",
    },
  }),
)(MuiIconButton);

interface Props {
  warehouseId: string;
  className: any;
}

export const ViewDropdown: FC<Props> = ({ warehouseId, className }) => {
  const [session] = useRecoilState(sessionState);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const isRiderScreenEnabled = remoteConfig.getBoolean("isRiderScreenEnabled");

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <span>
      <IconButton color="primary" className={className} onClick={handleClick}>
        <MoreIcon />
      </IconButton>
      <Menu
        id="view-dropdown"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={routes.manager.replace(":id", warehouseId)}
          onClick={handleClose}
          children="Manager"
        />
        <MenuItem
          component={Link}
          to={routes.statistics.replace(":id", warehouseId)}
          onClick={handleClose}
          children="Statistics"
        />

        {session.user?.admin && isRiderScreenEnabled && (
          <MenuItem
            component={Link}
            to={routes.riders.replace(":id", warehouseId)}
            onClick={handleClose}
            children="Rider"
          />
        )}
      </Menu>
    </span>
  );
};
