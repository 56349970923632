import { Button, CircularProgress } from "@material-ui/core";
import { useCustomerSupportModalStyles } from "../CustomerSupportModal.style";

interface Props {
  onButtonClick: () => void;
  loading: boolean;
  disabled?: boolean;
}

export const SubmitButton: React.FC<Props> = ({
  onButtonClick,
  loading,
  disabled = false,
}) => {
  const classes = useCustomerSupportModalStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onButtonClick}
      className={classes.button}
      children={
        loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          "Send Message"
        )
      }
      disabled={disabled}
    />
  );
};
