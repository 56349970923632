import { makeStyles, Paper } from "@material-ui/core";
import { FunctionComponent } from "react";
import cx from "classnames";

export const cardStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: ".5em .5em",
    borderRadius: "14px",
  },
  logo: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    borderRadius: 14,
    textAlign: "center",
  },
  logoSvg: {
    display: "block",
  },
  shortName: {
    letterSpacing: ".1em",
    fontSize: "1em",
    fontWeight: "bold",
  },
  card: {
    height: "100%",
    padding: "1em",
    textAlign: "center",
  },
  tiles: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.up("lg")]: {
      flexWrap: "nowrap",
    },
  },
  headTile: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 300,
    },
  },
  timersTile: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 200,
    },
  },
  head: {
    // display: "flex",
    // flexDirection: "row",
    flexDirection: "column",
    // [theme.breakpoints.up("sm")]: {
    //   flexDirection: "row",
    // },
    [theme.breakpoints.up("lg")]: {
      // maxWidth: 320,
      flexDirection: "column",
    },
  },

  totals: {
    order: 2,
    flex: 1,
    minWidth: "calc(100% - 16px)",
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("lg")]: {
      order: 0,
      flex: 1,
      minWidth: "auto",
    },
  },
  timers: {},
  item: {
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  summaryCard: {
    height: "100%",
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.status.idle.secondary}`,
  },
  time: {
    alignItems: "center",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  control: {
    padding: theme.spacing(2),
  },
  danger: {
    backgroundColor: theme.status.danger.primary,
    border: `2px solid ${theme.status.danger.secondary}`,
  },
  warning: {
    backgroundColor: theme.status.warning.primary,
    border: `2px solid ${theme.status.warning.secondary}`,
  },
  idle: {
    backgroundColor: theme.status.idle.primary,
    border: `2px solid ${theme.status.idle.secondary}`,
  },
  none: {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  paperIdle: {
    color: theme.status.idle.secondary,
  },
  paperWarning: {
    color: theme.status.warning.secondary,
  },
  paperDanger: {
    color: theme.status.danger.secondary,
  },
}));

export const Card: FunctionComponent<{
  className?: string;
}> = ({ children, className }) => {
  const classes = cardStyles();

  return <Paper className={cx(classes.root, className)}>{children}</Paper>;
};
