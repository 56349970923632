import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./pages/App";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import ErrorBoundary from "./components/ErrorBoundary";
import Error from "./pages/Error";
import { RecoilRoot } from "recoil";
//
// material ui theme
import theme from "./theme";

// fonts - material ui was built with roboto in mind

// some global css
import "./index.css";
import { unregister } from "./serviceWorkerRegistration";

Sentry.init({
  dsn: "https://ed4c37b3865e44a3afe6344e941eed75@o552852.ingest.sentry.io/6043754",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <RecoilRoot>
        <ErrorBoundary onError={console.error} errorComponent={<Error />}>
          <App />
        </ErrorBoundary>
      </RecoilRoot>
    </BrowserRouter>
  </MuiThemeProvider>,
  document.getElementById("root"),
);

// serviceWorkerRegistration.register();
unregister();
