import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Box,
  Typography,
  CircularProgress,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AppBar from "../components/AppBar";
import firestore from "../firebase/firestore";
import { WarehouseStats } from "@quickcommerceltd/zappboard";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  HeaderRow,
  TableCellCompact as TableCell,
  TableRow,
  TableSortLabel,
} from "../components/Table";
import { withAuthentication } from "./withAuthentication";
import ActiveTableCell from "../components/ActiveTableCell";
import routes from "./routes";
import { HomeStats } from "../components/HomeStats/HomeStats";
import { RevenueAndWorkloadStats } from "../components/RevenueAndWorkloadStats/RevenueAndWorkloadStats";
import { ViewDropdown } from "../components/ViewDropdown/ViewDropdown";
import { useMemo } from "react";
import orderBy from "lodash/orderBy";
import useLocalStorage from "react-use-localstorage";
import FormatDuration from "../components/FormatDuration";
import cx from "classnames";
import { useRecoilState } from "recoil";
import { sessionState } from "../state";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import { remoteConfig } from "../firebase";

enum Direction {
  ASC = "asc",
  DESC = "desc",
}

const UNFULFILLED_DANGER = 5; //minutes
const CANCELLED_DANGER = 5;
const UNASSIGNED_WARNING = 3;
const UNASSIGNED_DANGER = 8;
const WAITING_WARNING = 1;
const WAITING_DANGER = 2;
const PICKING_WARNING = 2;
const PICKING_DANGER = 4;
const ASSIGNED_WARNING = 3;
const ASSIGNED_DANGER = 8;
const ACTIVE_WARNING = 3;
const ACTIVE_DANGER = 8;

const MINUTE_IN_MS = 60000;
const DELIVERY_10AVG_WARNING = MINUTE_IN_MS * 15;
const DELIVERY_10AVG_DANGER = MINUTE_IN_MS * 18;
const DELIVERED_TO_PROMISE_DANGER = 90; //percent
const DELIVERED_TO_PROMISE_WARNING = 95; //percent
const DELIVERED_ON_TIME_DANGER = 85; //percent
const DELIVERED_ON_TIME_WARNING = 90; //percent

const DEFAULT_DELIVERY_TIME = 20;
const NECESSARY_OFFSET = 1; //Needed to use the FormatDuration component

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: "100%",
  },

  item: {
    width: "100%",
    paddingLeft: "1em",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
    backgroundColor: "#32a8c2",
  },

  storeClosed: {
    cursor: "pointer",
    backgroundColor: theme.status.danger.primary,
    "&:hover": {
      backgroundColor: `#8c1b30 !important`,
    },
  },

  storeDraftText: {
    cursor: "pointer",
    color: grey[600],
  },

  storeStatusCircle: {
    marginRight: 5,
    display: "inline-block",
    backgroundColor: theme.status.idle.primary,
    borderRadius: "50%",
    width: "8px",
    height: "8px",
    border: "1px solid #000",
  },

  storeStatusCircleClosed: {
    backgroundColor: theme.status.danger.primary,
  },

  storeStatusCircleDraft: {
    backgroundColor: grey[600],
  },

  storeStatusCirclePathDisabled: {
    backgroundColor: theme.status.warning.primary,
  },

  shortNameSize: {
    letterSpacing: ".1em",
  },

  warningText: {
    color: orange[500],
    fontWeight: "bold",
  },

  errorText: {
    color: red[500],
    fontWeight: "bold",
  },

  emptyCellNormalText: {
    color: grey[100],
    opacity: 0.5,
    fontSize: "0.9em",
    fontWeight: "normal",
  },
  emptyCellDraftText: {
    color: grey[600],
    opacity: 0.5,
    fontSize: "0.9em",
    fontWeight: "normal",
  },
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const [session] = useRecoilState(sessionState);
  const adminOrSupervisor = session.user?.admin || session.user?.supervisor;
  const manager = session.user?.manager;
  const managedWarehouses = session.user?.managedWarehouses;
  const [warehouses, loading, error] = useCollectionData<WarehouseStats>(
    !adminOrSupervisor && manager && managedWarehouses?.length
      ? firestore
          .collection("warehouseStats")
          .where("id", "in", managedWarehouses as any)
      : firestore.collection("warehouseStats"),
  );

  // table sorting feature
  const [storedSortSettings, setSortSettings] = useLocalStorage(
    "warehouseListOrder",
    undefined,
  );
  const sortSettings = useMemo(() => {
    try {
      return JSON.parse(storedSortSettings);
    } catch (error) {}

    return undefined;
  }, [storedSortSettings]);

  const sortedWarehouses = useMemo(() => {
    return orderBy(
      warehouses,
      sortSettings?.sortByProp ?? "picking.avgTotalTime10OrdersMs",
      sortSettings?.direction ?? Direction.DESC,
    );
  }, [warehouses, sortSettings]);

  const sortBy = (id: string, sortByProp: string) => {
    const direction =
      id !== sortSettings?.id || sortSettings?.direction !== Direction.DESC
        ? Direction.DESC
        : Direction.ASC;
    setSortSettings(JSON.stringify({ id, sortByProp, direction }));
  };

  const isRevenueStatsEnabled = useMemo(() => {
    try {
      const revenueStatsEnabledFor = remoteConfig.getString(
        "revenueStatsEnabledFor",
      );
      return revenueStatsEnabledFor.includes(session.user?.email as string);
    } catch (error) {
      return false;
    }
  }, [session.user?.email]);

  if (error) {
    return (
      <>
        <AppBar title="Home" />
        <Box padding={4}>{error.message}</Box>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <AppBar title="Home" />
        <Box key="loading" display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
      </>
    );
  }
  return (
    <>
      <AppBar title="Home" />

      <Box mt={2} mx={2}>
        <HomeStats warehouses={warehouses} />
        {isRevenueStatsEnabled && (
          <Box paddingTop="2vh">
            <RevenueAndWorkloadStats warehouses={warehouses} />
          </Box>
        )}
        <Box mb={2} />
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <HeaderRow>
                <TableCell>Code</TableCell>
                <TableCell>Name</TableCell>
                {[
                  {
                    id: "fulfilled",
                    label: "Fulfilled",
                    sortByProp: "orders.fulfilledOrdersNum",
                  },
                  {
                    id: "unfulfilled",
                    label: "Unfulfilled",
                    sortByProp: "orders.unfulfilledOrdersNum",
                  },
                  {
                    id: "cancelled",
                    label: "Cancelled",
                    sortByProp: "orders.cancelledOrdersNum",
                  },
                  {
                    id: "unassigned",
                    label: "Unassigned",
                    sortByProp: "tasks.unassignedTasksNum",
                  },
                  {
                    id: "waiting",
                    label: "Waiting",
                    sortByProp: "picking.waitingOrdersNum",
                  },
                  {
                    id: "picking",
                    label: "Picking",
                    sortByProp: "picking.packingOrdersNum",
                  },
                  {
                    id: "assigned",
                    label: "Assigned",
                    sortByProp: "tasks.assignedTasksNum",
                  },
                  {
                    id: "in-delivery",
                    label: "In Delivery",
                    sortByProp: "tasks.activeTasksNum",
                  },
                  {
                    id: "delivery-avg",
                    label: "Delivery Avg",
                    sortByProp: "picking.avgTotalTime10OrdersMs",
                  },
                  {
                    id: "throttled-delivery-time",
                    label: "Promised time",
                    sortByProp: "throttledDeliveryTime",
                  },
                  {
                    id: "delivered-to-promise",
                    label: "Delivered to promise",
                    sortByProp:
                      "tasks.completedTasksWithinThrottledPromisePercent",
                  },
                  {
                    id: "path-delivery-time",
                    label: "Polygon time",
                    sortByProp: "pathDeliveryTime",
                  },
                  {
                    id: "delivered-on-time",
                    label: "Delivered on time",
                    sortByProp: "tasks.completedTasksWithinPromisePercent",
                  },
                  {
                    id: "riders-online",
                    label: "Riders online",
                    sortByProp: "tasks.ridersOnline",
                  },
                ].map((item) => (
                  <TableCell key={item.id} align="right">
                    <TableSortLabel
                      active={item.id === sortSettings?.id}
                      direction={
                        item.id === sortSettings?.id
                          ? sortSettings?.direction
                          : Direction.DESC
                      }
                      onClick={() => sortBy(item.id, item.sortByProp)}
                    >
                      {item.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </HeaderRow>
            </TableHead>
            <TableBody>
              {(sortedWarehouses || []).map((wh) => (
                <TableRow
                  hover
                  key={wh.shortName}
                  className={cx({
                    [classes.storeClosed]:
                      wh.isTemporarilyClosed && !wh.isDraft,
                  })}
                  onClick={() => {
                    history.push(routes.manager.replace(":id", wh.id));
                  }}
                >
                  <TableCell align="left">
                    <Typography
                      noWrap
                      variant="caption"
                      className={cx(classes.shortNameSize, {
                        [classes.storeDraftText]:
                          wh.isDraft || !wh.isPathEnabled,
                      })}
                    >
                      <Tooltip
                        placement="bottom"
                        title={
                          wh.isDraft
                            ? "Draft"
                            : wh.isTemporarilyClosed
                            ? "Closed"
                            : !wh.isPathEnabled
                            ? "Closed Night/Autothrottle"
                            : "Open"
                        }
                      >
                        <span
                          className={cx(classes.storeStatusCircle, {
                            [classes.storeStatusCircleClosed]:
                              wh.isTemporarilyClosed && !wh.isDraft,
                            [classes.storeStatusCircleDraft]: wh.isDraft,
                            [classes.storeStatusCirclePathDisabled]:
                              !wh.isPathEnabled && !wh.isDraft,
                          })}
                        ></span>
                      </Tooltip>
                      {wh.shortName}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={cx({
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    <Typography variant="body2">{wh.name}</Typography>
                  </TableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.orders?.fulfilledOrdersNum}
                  </ActiveTableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.errorText]:
                        wh.orders?.unfulfilledOrdersNum > UNFULFILLED_DANGER &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.orders?.unfulfilledOrdersNum}
                  </ActiveTableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.errorText]:
                        wh.orders?.cancelledOrdersNum > CANCELLED_DANGER &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.orders?.cancelledOrdersNum}
                  </ActiveTableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.warningText]:
                        wh.tasks?.unassignedTasksNum >= UNASSIGNED_WARNING &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.errorText]:
                        wh.tasks?.unassignedTasksNum >= UNASSIGNED_DANGER &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.tasks?.unassignedTasksNum}
                  </ActiveTableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.warningText]:
                        wh.picking?.waitingOrdersNum >= WAITING_WARNING &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.errorText]:
                        wh.picking?.waitingOrdersNum >= WAITING_DANGER &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.picking?.waitingOrdersNum}
                  </ActiveTableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.warningText]:
                        wh.picking?.packingOrdersNum >= PICKING_WARNING &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.errorText]:
                        wh.picking?.packingOrdersNum >= PICKING_DANGER &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.picking?.packingOrdersNum}
                  </ActiveTableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.warningText]:
                        wh.tasks?.assignedTasksNum >= ASSIGNED_WARNING &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.errorText]:
                        wh.tasks?.assignedTasksNum >= ASSIGNED_DANGER &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.tasks?.assignedTasksNum}
                  </ActiveTableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.warningText]:
                        wh.tasks?.activeTasksNum >= ACTIVE_WARNING &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.errorText]:
                        wh.tasks?.activeTasksNum >= ACTIVE_DANGER &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.tasks?.activeTasksNum}
                  </ActiveTableCell>
                  <TableCell
                    align="right"
                    className={cx({
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {!!wh.picking?.avgTotalTime10OrdersMs ? (
                      <FormatDuration
                        from={NECESSARY_OFFSET}
                        to={
                          wh.picking?.avgTotalTime10OrdersMs + NECESSARY_OFFSET
                        }
                        warnAfter={DELIVERY_10AVG_WARNING}
                        dangerAfter={DELIVERY_10AVG_DANGER}
                      />
                    ) : (
                      <Typography
                        className={cx({
                          [classes.emptyCellNormalText]:
                            !wh.isDraft || wh.isPathEnabled,
                          [classes.emptyCellDraftText]:
                            wh.isDraft || !wh.isPathEnabled,
                        })}
                      >
                        -
                      </Typography>
                    )}
                  </TableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.errorText]:
                        wh.throttledDeliveryTime > DEFAULT_DELIVERY_TIME &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.throttledDeliveryTime > wh.pathDeliveryTime
                      ? wh.throttledDeliveryTime
                      : "-"}
                  </ActiveTableCell>
                  <TableCell
                    align="right"
                    className={cx({
                      [classes.warningText]:
                        wh.tasks?.completedTasksWithinThrottledPromisePercent <
                          DELIVERED_TO_PROMISE_WARNING &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.errorText]:
                        wh.tasks?.completedTasksWithinThrottledPromisePercent <=
                          DELIVERED_TO_PROMISE_DANGER &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {!!wh.orders?.fulfilledOrdersNum ? (
                      wh.tasks?.completedTasksWithinThrottledPromisePercent?.toFixed(
                        0,
                      ) + "%"
                    ) : (
                      <Typography
                        className={cx({
                          [classes.emptyCellNormalText]:
                            !wh.isDraft || wh.isPathEnabled,
                          [classes.emptyCellDraftText]:
                            wh.isDraft || !wh.isPathEnabled,
                        })}
                      >
                        -
                      </Typography>
                    )}
                  </TableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.pathDeliveryTime || "-"}
                  </ActiveTableCell>
                  <TableCell
                    align="right"
                    className={cx({
                      [classes.warningText]:
                        wh.tasks?.completedTasksWithinPromisePercent <
                          DELIVERED_ON_TIME_WARNING &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.errorText]:
                        wh.tasks?.completedTasksWithinPromisePercent <=
                          DELIVERED_ON_TIME_DANGER &&
                        !(wh.isDraft || !wh.isPathEnabled),
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {!!wh.orders?.fulfilledOrdersNum ? (
                      wh.tasks?.completedTasksWithinPromisePercent?.toFixed(0) +
                      "%"
                    ) : (
                      <Typography
                        className={cx({
                          [classes.emptyCellNormalText]:
                            !wh.isDraft || wh.isPathEnabled,
                          [classes.emptyCellDraftText]:
                            wh.isDraft || !wh.isPathEnabled,
                        })}
                      >
                        -
                      </Typography>
                    )}
                  </TableCell>
                  <ActiveTableCell
                    align="right"
                    className={cx({
                      [classes.storeDraftText]: wh.isDraft || !wh.isPathEnabled,
                    })}
                  >
                    {wh.tasks?.ridersOnline}
                  </ActiveTableCell>
                  <TableCell align="right">
                    <ViewDropdown
                      warehouseId={wh.id}
                      className={cx({
                        [classes.storeDraftText]:
                          wh.isDraft || !wh.isPathEnabled,
                      })}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default withAuthentication(Home);
