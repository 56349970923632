const routes = {
  home: "/",
  warehouse: "/warehouse/:id",
  statistics: "/statistics/:id",
  manager: "/manager/:id",
  riders: "/warehouse/:id/riders",
  signin: "/signin",
  authorize: "/authorize",
};

export default routes;
