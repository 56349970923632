import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  FulfillmentStatus,
  Order,
  TaskState,
} from "@quickcommerceltd/zappboard";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React from "react";
import OrderNumber from "../OrderNumber";

interface Props {
  name: string;
  orders: Order[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
  done: {
    opacity: 0.35,
  },
  unassigned: {
    backgroundColor: theme.status.danger.primary,
  },
  name: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

const useTableClasses = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "& $td": {
      borderBottomColor: "rgba(255,255,255,.1)",
    },
  },
}));

export const RiderCard: React.FC<Props> = ({ name, orders }) => {
  const styles = useStyles();
  const tableClasses = useTableClasses();
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography className={styles.name} variant="body2">
            {name === "undefined" || !name ? "Unassigned" : name}
          </Typography>
        </Box>
      </CardContent>
      <Box height={140}>
        <Table size="small" classes={tableClasses}>
          <TableBody>
            {orders.slice(0, 5).map((order) => (
              <TableRow
                key={order.number}
                className={cx({
                  [styles.root]: true,
                  [styles.done]: [
                    FulfillmentStatus.FULFILLED,
                    FulfillmentStatus.CANCELLED,
                  ].includes(order.fulfillmentStatus),
                  [styles.unassigned]:
                    order.deliveryStatus === TaskState.UNASSIGNED,
                })}
              >
                <TableCell>
                  <OrderNumber value={order.number} />
                </TableCell>
                <TableCell>{order.deliveryStatus}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default RiderCard;
