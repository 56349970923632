import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@material-ui/core";
import {
  Order,
  SupportInquiry,
  SupportInquiryCode,
} from "@quickcommerceltd/zappboard";
import { FC, useCallback, useState } from "react";
import cx from "classnames";
import { functions } from "../../firebase";
import DoneIcon from "@material-ui/icons/Done";
import { useCustomerSupportModalStyles } from "./CustomerSupportModal.style";
import CloseIcon from "@material-ui/icons/Close";
import {
  CustomerSupportDelayed,
  CustomerSupportOOS,
  CustomerSupportCancelled,
  CustomerSupportOther,
} from "./_components";

const DEFAULT_REQUEST = { code: undefined, message: "" };

interface Props {
  open: boolean;
  order: Order;
  userEmail: string;
  onClose: () => void;
  isOptionOOSForContactSupportEnabled: boolean;
}

export const CustomerSupportModal: FC<Props> = ({
  open,
  order,
  userEmail,
  onClose,
  isOptionOOSForContactSupportEnabled,
}) => {
  const classes = useCustomerSupportModalStyles();
  const [loading, setLoading] = useState(false);
  const [ticketId, setTicketId] = useState("");
  const [request, setRequest] =
    useState<Partial<SupportInquiry>>(DEFAULT_REQUEST);
  const [reason, setReason] = useState("");

  const reset = () => {
    setRequest(DEFAULT_REQUEST);
    setTicketId("");
    setReason("");
    setLoading(false);
  };

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const handleCodeChange = (event: any) => {
    reset();
    const code: SupportInquiryCode = event.target.value;
    setReason(code);
    setRequest((inquiry: Partial<SupportInquiry>) => ({
      ...inquiry,
      code,
    }));
  };

  const submitSupportRequest = useCallback(async () => {
    if (!request.code || loading) return;

    setLoading(true);
    const createSupportInquiry = functions.httpsCallable(
      "api-createSupportInquiry",
      {},
    );

    createSupportInquiry({
      userEmail,
      warehouseId: order.warehouseId,
      orderId: order.id,
      orderNumber: order.number,
      orderLinesLength: order.lines?.length || 0,
      taskId: order.deliveryTaskId,
      ...request,
    })
      .then((response) => {
        setTicketId(response.data ?? "undefined");
        setLoading(false);
      })
      .catch((error: any) => {
        console.error(
          `Could not create a support inquiry for ${order?.id} with error:`,
          error,
        );
        setLoading(false);
      });
  }, [request, loading, order, userEmail]);

  return (
    <Modal open={open}>
      <Box className={classes.paper}>
        <CloseIcon onClick={handleOnClose} className={classes.closeIcon} />

        {/* ISSUE CREATION SCREEN */}
        {!ticketId && (
          <>
            <Typography variant="h4" className={classes.text}>
              Contact Customer Support
            </Typography>
            <Box className={classes.subtitleBox} display="flex">
              <Box alignContent="left">
                <Typography variant="body1" className={classes.text}>
                  Order: #{order.number}
                </Typography>
              </Box>
              <Box alignContent="right">
                <Typography variant="body1" className={classes.text}>
                  {order.warehouseId}
                </Typography>
              </Box>
            </Box>
            <FormControl
              variant="outlined"
              className={cx(classes.select, classes.formControl)}
            >
              <InputLabel>Select the ticket reason</InputLabel>
              <Select
                id="code"
                label="Select the ticket reason"
                value={request.code || ""}
                onChange={handleCodeChange}
                disabled={loading}
              >
                <MenuItem value="" disabled>
                  Select the ticket reason
                </MenuItem>
                {Object.keys(SupportInquiryCode)
                  .sort()
                  .filter((key) => {
                    if (isOptionOOSForContactSupportEnabled) {
                      return true;
                    }
                    return key !== SupportInquiryCode.ITEM_OOS;
                  })
                  .map((code) => (
                    <MenuItem key={code} value={code}>
                      {code}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {reason === SupportInquiryCode.ITEM_OOS ? (
              <CustomerSupportOOS
                onButtonClick={submitSupportRequest}
                loading={loading}
                order={order}
                setRequest={(v) => setRequest(v)}
              />
            ) : reason === SupportInquiryCode.DELIVERY_DELAY ? (
              <CustomerSupportDelayed
                onButtonClick={submitSupportRequest}
                loading={loading}
                setRequest={(v) => setRequest(v)}
              />
            ) : reason === SupportInquiryCode.CANCEL_ORDER ? (
              <CustomerSupportCancelled
                onButtonClick={submitSupportRequest}
                loading={loading}
                setRequest={(v) => setRequest(v)}
              />
            ) : reason === SupportInquiryCode.OTHER ? (
              <CustomerSupportOther
                onButtonClick={submitSupportRequest}
                loading={loading}
                setRequest={(v) => setRequest(v)}
              />
            ) : (
              ""
            )}
          </>
        )}

        {/* SUCCESS SCREEN */}
        {ticketId && (
          <>
            <DoneIcon className={classes.successIcon} />
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Thank you!
            </Typography>
            <Typography
              variant="body2"
              style={{ textAlign: "center", marginBottom: "32px" }}
            >
              A ticket with id #{ticketId} has been created.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOnClose}
              className={classes.button}
              children="Close"
            />
          </>
        )}
      </Box>
    </Modal>
  );
};
