import { Radio } from "@material-ui/core";
import cx from "classnames";
import { useCustomerSupportModalStyles } from "../CustomerSupportModal.style";

export const StyledRadio = (props: any) => {
  const classes = useCustomerSupportModalStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};
