import { Box, DialogTitle, IconButton, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) => ({
  head: { paddingBottom: 0, paddingRight: 40 },
}));

export const DialogHead: FC<{ onClose: () => void }> = ({
  children,
  onClose,
}) => {
  const styles = useStyles();
  return (
    <DialogTitle className={styles.head}>
      {children}
      <Box position="absolute" top={0} right={0} margin={1}>
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  );
};
