import { Button, Menu, MenuItem } from "@material-ui/core";
import { firebase } from "../../firebase";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import routes from "../../pages/routes";
import { sessionState } from "../../state";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { SyntheticEvent, useState } from "react";

export const UserDropdown = () => {
  const [session, setSession] = useRecoilState(sessionState);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    handleClose();
    setSession({ user: null, isAuthenticating: false });

    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("You've been logged out");
        history.push(routes.signin);
      });
  };

  return (
    <span style={{ position: "relative" }}>
      {session?.user && (
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="text"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
        >
          {session.user.email}
        </Button>
      )}
      <Menu
        id="customized-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        PaperProps={{ style: { minWidth: 220 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={logOut}>Log out</MenuItem>
      </Menu>
    </span>
  );
};
