import { FC, useMemo, useState } from "react";
import { WarehouseStats } from "@quickcommerceltd/zappboard";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firestore from "../firebase/firestore";
import { withAuthentication } from "./withAuthentication";
import AppBar from "../components/AppBar";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  HeaderRow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../components/Table";

const MAX_MANAGED_WAREHOUSES = 10;

export interface UserClaim {
  id: string; // email address as doc.id
  note: string;
  admin: boolean | undefined;
  supervisor: boolean | undefined;
  manager: boolean | undefined;
  managedWarehouses: string[];
}

enum UserRole {
  ADMIN = "admin",
  SUPERVISOR = "supervisor",
  MANAGER = "manager",
}

const useStyles = makeStyles(() =>
  createStyles({
    textfield: {
      flexGrow: 1,
      minWidth: "200px",
      marginRight: "8px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#AFB3D8",
        },
      },
      "& .MuiOutlinedInput-input": {
        color: "white",
      },
      "& .MuiInputLabel-root": {
        color: "white",
      },
    },
  }),
);

const Authorize: FC = () => {
  const classes = useStyles();
  const [editUser, setEditUser] = useState({} as UserClaim);

  const [userList, loading, error] = useCollectionData<UserClaim>(
    firestore.collection("user_claims"),
  );

  const [warehouseStats] = useCollectionData<WarehouseStats>(
    firestore.collection("warehouseStats"),
  );

  const warehouseIds = useMemo(() => {
    return (warehouseStats ?? []).map((wh) => wh.id);
  }, [warehouseStats]);

  const handleChange = (event: any) => {
    const { name, value } = event.target ?? {};

    if (name === "role") {
      setEditUser({
        ...editUser,
        admin: false,
        supervisor: false,
        manager: false,
        [value]: true,
      });
      return;
    }

    if (name === "managedWarehouses") {
      if (value?.length > MAX_MANAGED_WAREHOUSES) {
        alert(`You can select only ${MAX_MANAGED_WAREHOUSES} Warehouses.`);
      }

      setEditUser({
        ...editUser, 
        [name]: value?.splice(0, MAX_MANAGED_WAREHOUSES),
      });
      return;
    }

    setEditUser({ ...editUser, [name]: value });
  };

  const deleteUser = (user: UserClaim): void => {
    if (!user?.id) return;
    firestore.collection("user_claims").doc(user.id).delete();
  };

  const saveUser = (user: UserClaim) => {
    if (!user?.id) return;
    firestore.collection("user_claims").doc(user.id).set(user);
  };

  if (error) {
    return (
      <>
        <AppBar title="Authorize" />
        <Box padding={4} style={{ textAlign: "center" }}>
          {error.name}: {error.message}
        </Box>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <AppBar title="Authorize" />
        <Box key="loading" display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  return (
    <>
      <AppBar title="Authorize" />

      {/* Create/Edit */}
      <Box m={4}>
        <Typography variant="h6" style={{ marginBottom: "8px" }}>
          Create/Edit
        </Typography>
        <Box display="flex">
          <TextField
            name="id"
            variant="outlined"
            label="Email"
            value={editUser.id ?? ""}
            onChange={handleChange}
            classes={{ root: classes.textfield }}
          />
          <TextField
            name="note"
            variant="outlined"
            label="Note"
            value={editUser.note ?? ""}
            onChange={handleChange}
            classes={{ root: classes.textfield }}
          />
          <TextField
            select
            name="role"
            variant="outlined"
            label="user roles"
            SelectProps={{
              multiple: false,
              value: [
                editUser.admin && UserRole.ADMIN,
                editUser.supervisor && UserRole.SUPERVISOR,
                editUser.manager && UserRole.MANAGER,
              ].filter(Boolean),
              onChange: handleChange,
            }}
            classes={{ root: classes.textfield }}>
            {[
              UserRole.ADMIN, 
              UserRole.SUPERVISOR, 
              UserRole.MANAGER,
            ].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            name="managedWarehouses"
            variant="outlined"
            label="warehouses"
            SelectProps={{
              multiple: true,
              value: editUser.managedWarehouses ?? [],
              onChange: handleChange,
            }}
            classes={{ root: classes.textfield }}>
            {warehouseIds.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Button color="primary" onClick={() => setEditUser({} as UserClaim)}>
            cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => saveUser(editUser)}>
            Save
          </Button>
        </Box>
      </Box>

      {/* UserList */}
      <Box ml={4} mr={4}>
        <Typography variant="h6">Users</Typography>
        <TableContainer>
          <Table size="small" aria-label="customized table">
            <TableHead>
              <HeaderRow>
                <TableCell>Email</TableCell>
                <TableCell>Note</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Warehouses</TableCell>
                <TableCell>Actions</TableCell>
              </HeaderRow>
            </TableHead>
            <TableBody>
              {userList?.map((user: UserClaim, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {user.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {user.note}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {[
                      user.admin && UserRole.ADMIN, 
                      user.supervisor && UserRole.SUPERVISOR,
                      user.manager && UserRole.MANAGER,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {user.managedWarehouses?.join(", ")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Button
                      onClick={() => setEditUser(user)}
                      size="small"
                      color="primary"
                      variant="outlined">
                      edit
                    </Button>
                    <Button
                      onClick={() => deleteUser(user)}
                      size="small"
                      color="primary">
                      del
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default withAuthentication(Authorize);
