import { TableCellProps } from "@material-ui/core";
import { alpha, makeStyles, Theme } from "@material-ui/core/styles";
import cx from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { TableCellCompact as TableCell } from "./Table";
import debounce from "lodash/debounce";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    transition: "all 0.15s ease-in-out",
  },
  highlight: {
    background: alpha(theme.palette.primary.main, 0.5),

    "& > $value": {
      transition: "all 0s ease-in-out",
      transform: "scale(1.2)",
    },
  },
  value: {
    display: "block",
    transformOrigin: "right",
    transition: "all 0.15s ease-in-out",
  },
  empty: {
    opacity: 0.5,
  },
}));
export const ActiveTableCell: React.FC<TableCellProps> = ({
  children,
  className,
  ...props
}) => {
  const classes = useStyles();
  const [isChanged, setIsChanged] = useState(false);
  const wasUpdated = useMemo(() => {
    return debounce(() => {
      setIsChanged(false);
    }, 500);
  }, []);

  useEffect(() => {
    setIsChanged(true);
    wasUpdated();
    return () => wasUpdated.cancel();
  }, [children, wasUpdated]);

  return (
    <TableCell
      {...props}
      className={cx(classes.root, className, {
        [classes.highlight]: isChanged,
      })}
    >
      <span className={classes.value}>
        {children || <small className={classes.empty}>-</small>}
      </span>
    </TableCell>
  );
};

export default ActiveTableCell;
