import { makeStyles } from "@material-ui/core";

export const useCustomerSupportModalStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min(100%, 600px)",
    padding: theme.spacing(6, 4, 4),
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  closeIcon: {
    position: "absolute",
    top: "12px",
    right: "12px",
    fontSize: "2rem",
    cursor: "pointer",
  },
  text: {
    marginBottom: "16px",
  },
  formControl: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiFormLabel-root": {
      color: "#DADADA",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
  },
  select: {
    margin: "16px 0",
  },
  button: {
    width: "100%",
    height: "36px",
    marginTop: "16px",
  },
  successIcon: {
    display: "block",
    position: "relative",
    margin: "32px auto",
    fontSize: "72px",
    color: theme.palette.success.main,
    padding: "8px",
    boxSizing: "border-box",
    border: `4px solid ${theme.palette.success.main}`,
    borderRadius: "100%",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    marginLeft: "8px",
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tableContainerOOS: {
    maxHeight: "20em",
    marginTop: "16px",
  },
  productImage: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  productImageCell: {
    paddingRight: 0,
    width: theme.spacing(4),
  },
  productImageBig: {
    width: 255,
    height: 255,
    margin: "0 auto",
  },
  productImageLarge: {
    maxWidth: "100%",
  },
  inputOOS: {
    maxWidth: "40px",
  },
  markItemLineOOS: {
    backgroundColor: theme.status.warning.secondary,
    "&:hover": {
      background: `${theme.status.warning.primary} !important`,
    },
  },
  topRadio: {
    marginTop: "8px",
  },
  subtitleBox: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  reasonHeadline: {
    padding: "4px 64px",
    textAlign: "center",
  },
}));
