import {
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import { DateTime } from "luxon";
import {
  OrderHistoryEvent,
  parseOrderHistoryEvent,
} from "./_helpers/parseOrderHistory";
import cx from "classnames";
import { functions } from "../../firebase";

const useStyles = makeStyles((theme: Theme) => ({
  line: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  lineMarker: {
    marginLeft: "12px",
    display: "inline-block",
    backgroundColor: theme.palette.success.main,
    borderRadius: "50%",
    width: "8px",
    height: "8px",
    border: "1px solid #000",
  },
  refresh: {
    marginTop: -theme.spacing(1),
  },
  refreshIcon: {
    color: "#02BAFF",
    fontSize: "0.9em",
  },

  date: {
    marginLeft: "4px",
    whiteSpace: "nowrap",
    fontSize: "0.9em",
  },

  linkStyle: {
    color: "#02BAFF",
  },

  "@keyframes rotating": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },

  rotating: {
    animation: "$rotating 2s linear infinite",
  },

  noHistoryStyle: {
    lineHeight: "2.5em",
  },
}));

export const OrderHistory: FC<{ id: String }> = ({ id }) => {
  let orderId = id;
  const classes = useStyles();

  const [data, setData] = useState<any[] | undefined>();
  const [loading, setLoading] = useState(false);

  const getEvents = async () => {
    setLoading(true);
    const getZCommerceOrderHistory = functions.httpsCallable(
      "api-getZCommerceOrderHistory",
    );

    getZCommerceOrderHistory({ orderId })
      .then((response) => {
        setLoading(false);
        const orderHistory: any[] = response.data?.map(
          (event: any): OrderHistoryEvent => {
            return parseOrderHistoryEvent(event);
          },
        );
        setData(orderHistory?.reverse());
      })
      .catch((error) => {
        console.log(
          `Could not fetch order history for ${orderId} with error:`,
          error,
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getEvents();
  }, [orderId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box display="flex" alignItems="center" position="relative">
        <Typography variant="h5" gutterBottom>
          Order History{" "}
        </Typography>

        <Box className={classes.refresh}>
          <IconButton
            className={cx({
              [classes.rotating]: loading,
            })}
            onClick={() => {
              getEvents();
            }}
          >
            <RefreshIcon
              fontSize="medium"
              className={classes.refreshIcon}
            ></RefreshIcon>
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box my={2}>
        {!data ? (
          <span className={classes.noHistoryStyle}>
            Could not fetch order history
          </span>
        ) : (
          data?.map((event: OrderHistoryEvent, index: number) => {
            return (
              <Grid container spacing={1} key={index} className={classes.line}>
                <Grid item xs={1}>
                  <span className={classes.lineMarker} />
                </Grid>
                <Grid item xs={9}>
                  {event.link ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={event.link}
                      className={classes.linkStyle}
                    >
                      {event.message}
                    </a>
                  ) : (
                    event.message
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      event.date
                        ? DateTime.fromISO(event.date).toLocaleString(
                            DateTime.DATETIME_FULL_WITH_SECONDS,
                          )
                        : "-"
                    }
                  >
                    <span className={classes.date}>
                      {DateTime.fromISO(event.date).toRelative({
                        style: "short",
                      })}
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            );
          })
        )}
      </Box>
    </>
  );
};
