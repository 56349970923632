import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import MinimizeIcon from "@material-ui/icons/Minimize";
import { makeStyles } from "@material-ui/styles";
import {
  FulfillmentStatus,
  Order,
  PickingStatus,
} from "@quickcommerceltd/zappboard";
import cx from "classnames";
import { FC, useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { env } from "../../config";
import { remoteConfig } from "../../firebase";
import { sessionState } from "../../state";
import { ReactComponent as FraudCheck } from "../../svg/fraud.svg";
import { useOrderStyles } from "../../utils/hooks/useOrderStyles";
import { CustomerSupportModal } from "../CustomerSupportModal/CustomerSupportModal";
import { OrderHistory } from "../OrderHistory/OrderHistory";
import { OrderLines } from "../OrderLines/OrderLines";
import { PickingDeviceAssignmentModal } from "../PickingDeviceAssignmentModal/PickingDeviceAssignmentModal";
import { Rating } from "../Rating/Rating";

const MINUTE_IN_MS = 60000;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.paper,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
    position: "fixed",
    bottom: 64,
    left: 0,
    width: "100%",
    height: "42vh",
    borderTop: "solid 2px #fff",
    maxHeight: 400,
  },
  badge: { borderRadius: 4, textTransform: "uppercase", fontWeight: 600 },
  fraudCheck: { background: theme.palette.success.main },
  close: {
    padding: "12px 4px",
  },
  disabledAssignButton: {
    "&.Mui-disabled": {
      color: grey[700],
      border: "1px solid #616161",
    },
  },
}));

export const OrderDetails: FC<{
  order: Order;
  onClose: (id: string) => void;
  onMinimize: (id: string) => void;
}> = ({ order, onClose, onMinimize }) => {
  const [session] = useRecoilState(sessionState);
  const styles = useStyles();
  const orderStyles = useOrderStyles();
  const [showCustomerSupportModal, setShowCustomerSupportModal] =
    useState(false);
  const [showPickingDeviceModal, setShowPickingDeviceModal] = useState(false);

  const close = () => {
    onClose(order.id);
  };

  const minimize = () => {
    onMinimize(order.id);
  };

  const toggleCustomerSupportModal = () => {
    setShowCustomerSupportModal(!showCustomerSupportModal);
  };
  const togglePickingDeviceModal = () => {
    setShowPickingDeviceModal(!showPickingDeviceModal);
  };

  const {
    isOrderHistoryEnabled,
    isContactCustomerSupportEnabled,
    isPickingDeviceAssignmentEnabled,
    isOptionOOSForContactSupportEnabled,
  } = useFeatureFlags(order);

  const linkToZCommerceOrder = `https://${
    env ?? "stg"
  }.zcommerce.quickcommerce.org/dashboard/orders/${order.id ?? ""}`;

  const isAssignToPickerDisabled =
    order.pickingStatus === PickingStatus.PACKED ||
    order.fulfillmentStatus !== FulfillmentStatus.UNFULFILLED;

  return (
    <>
      <Box
        className={styles.root}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.code === "Escape") {
            close();
          }
        }}
      >
        <Box padding={3} overflow="auto" height="1">
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
            <Box position="fixed" left={-1000}>
              <button autoFocus id="focus-trap" />
            </Box>
            <Box mr={2}>
              <Typography variant="h4">
                Order{" "}
                <Link
                  href={linkToZCommerceOrder}
                  underline="none"
                  color="inherit"
                  rel="noopener"
                  target="_blank"
                >
                  #{order.number}
                </Link>
              </Typography>
            </Box>
            <Button
              style={{
                minWidth: "28px",
                maxWidth: "28px",
                marginLeft: "-8px",
                marginRight: "8px",
              }}
              onClick={() => navigator.clipboard.writeText(`${order.number}`)}
            >
              <Tooltip title={"Copy order number to clipboard"}>
                <FileCopyOutlinedIcon color="primary"></FileCopyOutlinedIcon>
              </Tooltip>
            </Button>
            {order.isNewCustomer && (
              <Box mr={1}>
                <Chip
                  color="primary"
                  className={cx(styles.badge)}
                  label="new customer"
                />
              </Box>
            )}

            <Box mr={1}>
              <Chip
                color="primary"
                className={cx(
                  styles.badge,
                  orderStyles[order.pickingStatus],
                  orderStyles[order.deliveryStatus],
                  orderStyles[order.fulfillmentStatus],
                )}
                label={order.fulfillmentStatus}
              />
            </Box>

            <Box mr={1}>
              {order.isFraud && (
                <Chip
                  color="primary"
                  className={cx(styles.badge, styles.fraudCheck)}
                  icon={<FraudCheck />}
                  label="Fraud check"
                />
              )}
            </Box>

            {!!order.customerRating?.rating && (
              <Box mr={1}>
                <Rating
                  rating={order.customerRating.rating}
                  note={order.customerRating?.note}
                />
              </Box>
            )}

            <Box
              display="flex"
              flexGrow="1"
              mt={-2}
              justifyContent="flex-end"
              mr={8}
            >
              {isPickingDeviceAssignmentEnabled && (
                <Box mr={1}>
                  <Tooltip
                    title={
                      isAssignToPickerDisabled
                        ? "Order fulfilled, cancelled or packed"
                        : ""
                    }
                    placement="left"
                  >
                    <span>
                      <Button
                        variant="outlined"
                        color="primary"
                        children="Assign To Picker"
                        onClick={togglePickingDeviceModal}
                        disabled={isAssignToPickerDisabled}
                        classes={{ disabled: styles.disabledAssignButton }}
                      />
                    </span>
                  </Tooltip>
                  <PickingDeviceAssignmentModal
                    open={showPickingDeviceModal}
                    orderId={order.id}
                    warehouseId={order.warehouseId}
                    onClose={togglePickingDeviceModal}
                  />
                </Box>
              )}

              {!session.user?.supervisor && isContactCustomerSupportEnabled && (
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    children="Contact Support"
                    onClick={toggleCustomerSupportModal}
                  />
                  <CustomerSupportModal
                    open={showCustomerSupportModal}
                    order={order}
                    userEmail={session.user?.email || ""}
                    onClose={toggleCustomerSupportModal}
                    isOptionOOSForContactSupportEnabled={
                      isOptionOOSForContactSupportEnabled
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Grid container spacing={6}>
            <Grid
              item
              xs={12}
              sm={isOrderHistoryEnabled ? 12 : 8}
              md={isOrderHistoryEnabled ? 4 : 8}
              lg={isOrderHistoryEnabled ? 4 : 8}
            >
              <Typography variant="h5"></Typography>
              <Box ml={-1}>
                <OrderLines order={order} />
              </Box>

              {/* {order.totalCostGross} */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={isOrderHistoryEnabled ? 12 : 4}
              md={4}
              lg={4}
            >
              <Typography variant="h5" gutterBottom>
                Metadata
              </Typography>
              {[
                {
                  label: "Promised delivery time",
                  value: order.promisedDeliveryTimeV2
                    ? `${order.promisedDeliveryTimeV2 / MINUTE_IN_MS}-${
                        order.promisedDeliveryTimeV2 / MINUTE_IN_MS + 10
                      }min`
                    : `${order.promisedDeliveryTime / MINUTE_IN_MS}min`,
                },
                {
                  label: "Shipping method",
                  value: order.shippingMethod,
                },
                {
                  label: "Delivery option",
                  value: order.metadata.addressDeliveryOption,
                },
                {
                  label: "Rider note",
                  value: order.metadata.riderNote,
                },
              ].map(({ label, value }) => (
                <div key={label}>
                  <Box my={1}>
                    <Divider />
                  </Box>
                  <Typography>
                    <b>{label}</b>
                    <br />
                    {value || "—"}
                  </Typography>
                </div>
              ))}
            </Grid>
            {isOrderHistoryEnabled ? (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <OrderHistory id={order.id} />
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </Box>
        <Box padding={1} position="absolute" right={0} top={0}>
          <IconButton
            color="inherit"
            className={cx(styles.close)}
            onClick={minimize}
          >
            <MinimizeIcon />
          </IconButton>
          <IconButton
            color="inherit"
            className={cx(styles.close)}
            onClick={close}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

const useFeatureFlags = (order: Order) => {
  const [remoteConfigs, setRemoteConfigs] = useState<any>({});
  useEffect(() => {
    try {
      setRemoteConfigs(remoteConfig.getAll());
    } catch {
      setRemoteConfigs({});
    }
  }, [order]);

  const getFeatureFlag = useCallback(
    (remoteConfigName: string) => {
      try {
        const remoteConfigRecord: Record<string, boolean> = JSON.parse(
          remoteConfigs[remoteConfigName].asString(),
        );
        return remoteConfigRecord[order.warehouseId] ?? remoteConfigRecord.all;
      } catch {
        return false;
      }
    },
    [order, remoteConfigs],
  );

  return {
    isPickingDeviceAssignmentEnabled: getFeatureFlag(
      "isPickingDeviceAssignmentEnabled",
    ),
    isOrderHistoryEnabled: getFeatureFlag("isOrderHistoryEnabled"),
    isContactCustomerSupportEnabled: getFeatureFlag(
      "isContactCustomerSupportEnabled",
    ),
    isOptionOOSForContactSupportEnabled: getFeatureFlag(
      "isOptionOOSForContactSupportEnabled",
    ),
  };
};
