import Box from "@material-ui/core/Box";
import {
  Order,
  WarehouseStats,
  ShippingMethod,
  FulfillmentStatus,
  PickingStatus,
} from "@quickcommerceltd/zappboard";
import { firebase } from "../firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import AppBar from "../components/AppBar";
import { Title } from "../components/Text";
import firestore from "../firebase/firestore";
import routes from "./routes";
import { withAuthentication } from "./withAuthentication";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { OrdersList } from "../components/OrdersList/OrdersList";
import { Searchbar } from "../components/Searchbar/Searchbar";
import includes from "lodash/includes";
import startsWith from "lodash/startsWith";
import { OrderTabs } from "../components/OrderTabs/OrderTabs";
import { SoundEffect } from "../components/SoundEffect";
import { usePaginationData } from "../hooks/usePagination";
import { ORDERS_PERIOD_MS } from "../config";
import { useRecoilState } from "recoil";
import { sessionState } from "../state";
import { remoteConfig } from "../firebase/firebase";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Manager: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [stats, loading] = useDocumentData<WarehouseStats>(
    firestore.doc(`warehouseStats/${id}`),
  );
  const query = useQuery();
  const [session] = useRecoilState(sessionState);
  const orderId = query.get("orderId") ?? undefined;
  const [searchTerm, setSearchTerm] = useState("");
  const now = useMemo(() => firebase.firestore.Timestamp.now().toMillis(), []);
  const [infiniteScroll, setInfiniteScrollEnabled] = useState(false);

  useEffect(() => {
    remoteConfig.fetchAndActivate();
    const enabled = remoteConfig.getBoolean("isInfiniteScrollEnabled");
    console.debug("infinite scroll enabled", enabled);
    setInfiniteScrollEnabled(enabled);
  }, []);

  const statsQuery = useMemo(() => {
    let q = firestore
      .doc(`warehouseStats/${id}`)
      .collection(`orders`)
      .orderBy("createdAt", "desc");

    if (!session.user?.admin) {
      q = q.where("createdAt", ">", now - ORDERS_PERIOD_MS);
    }

    return q;
  }, [id, now, session.user?.admin]);

  const [orders, { loadMore }] = usePaginationData<Order>(statsQuery, {
    idField: "id",
    limit: 50,
  });

  const waitingOrders = useMemo(() => {
    return (
      orders?.filter(
        (o) =>
          o.fulfillmentStatus === FulfillmentStatus.UNFULFILLED &&
          o.pickingStatus === PickingStatus.OPEN,
      )?.length ?? 0
    );
  }, [orders]);

  const filteredOrders = useMemo(() => {
    if (!searchTerm) {
      return orders;
    }

    const contains = (
      value: string = "",
      termList: string[],
      func: Function,
    ) => {
      return termList.some((term) =>
        func(value.toLowerCase(), term.toLowerCase()),
      );
    };

    return orders?.filter((order) => {
      const searchTermList = searchTerm.split(" ").filter(Boolean);
      const { streetAddress1 } = order.shippingAddress;

      const containsOrderNumber = contains(
        String(order.number),
        searchTermList,
        includes,
      );
      const containsAddress = contains(
        streetAddress1,
        searchTermList,
        includes,
      );
      const containsPicker = contains(
        order.picker?.name,
        searchTermList,
        includes,
      );
      const containsRider = contains(
        order.rider?.name,
        searchTermList,
        includes,
      );
      const isFulfillmentStatus = contains(
        order.fulfillmentStatus,
        searchTermList,
        startsWith,
      );
      const isDeliveryStatus = contains(
        order.deliveryStatus ?? ShippingMethod.PICKUP,
        searchTermList,
        startsWith,
      );
      const isPickingStatus = contains(
        order.pickingStatus,
        searchTermList,
        startsWith,
      );

      return [
        containsOrderNumber,
        containsAddress,
        containsPicker,
        containsRider,
        isFulfillmentStatus,
        isDeliveryStatus,
        isPickingStatus,
      ].some(Boolean);
    });
  }, [orders, searchTerm]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("board", stats, orders);
    }
  }, [stats, orders]);

  if (!loading && !stats) {
    return (
      <>
        <Box display="flex" justifyContent="center" padding={4}>
          <Title>Not found</Title>
        </Box>
      </>
    );
  }

  if (!stats) {
    return (
      <>
        <Box display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  return (
    <>
      <SoundEffect value={waitingOrders} />
      <AppBar
        title={`${stats.shortName} • ${stats.pickUpAddress?.city}`}
        backTo={routes.home}
        searchbar={<Searchbar onSearch={setSearchTerm} />}
        actions={
          <Box display="none">
            <Button
              component={Link}
              variant="contained"
              to={routes.statistics.replace(":id", id)}
            >
              Stats
            </Button>
            <Box mx={1} />
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={routes.riders.replace(":id", id)}
            >
              Riders
            </Button>
          </Box>
        }
      />

      <InfiniteScroll
        dataLength={orders.length}
        next={loadMore}
        hasMore={infiniteScroll}
        loader={null}
        endMessage={null}
        refreshFunction={() => {}}
      >
        <Box paddingBottom="50vh">
          <Box padding={0}>
            <OrdersList
              interactive
              selectedOrderId={orderId}
              orders={filteredOrders ?? []}
            />
          </Box>
        </Box>
      </InfiniteScroll>

      <OrderTabs activeOrderId={orderId} orders={orders ?? []} />
    </>
  );
};

export default withAuthentication(Manager);
