import {
  Avatar,
  Box,
  Tooltip,
  Dialog,
  DialogContent,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Order, Line } from "@quickcommerceltd/zappboard";
import { FC, useState } from "react";
import { DialogHead } from "../DialogHead/DialogHead";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../Table";
import cx from "classnames";

interface OrderPickedItems extends Order {
  pickedItems?: {
    [key: string]: number;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  productImage: { width: theme.spacing(4), height: theme.spacing(4) },
  productImageCell: { paddingRight: 0, width: theme.spacing(4) },
  productImageBig: { width: 255, height: 255, margin: "0 auto" },
  productImageLarge: { maxWidth: "100%" },
  pickedItemPartialMissing: { color: theme.palette.warning.main },
  pickedItemMissing: { color: theme.palette.error.main },
}));

export const OrderLines: FC<{ order: OrderPickedItems }> = ({ order }) => {
  const styles = useStyles();
  const [line, toggleLine] = useState<Line | null>(null);

  return (
    <>
      <Dialog onClose={() => toggleLine(null)} open={!!line}>
        <DialogHead onClose={() => toggleLine(null)}>
          {line?.productName}
        </DialogHead>
        {line && (
          <DialogContent>
            <Box mb={2}>
              <div>
                <Typography variant="body2">{line?.productSku}</Typography>
              </div>
              <div>{line.variantName}</div>
              <div>
                Unit price: <b>{line.unitPriceNet}</b>{" "}
                <small>{line.currency}</small>
              </div>
              <div>
                Quantity: <b>{line.quantity}</b>
              </div>
            </Box>
            <img
              alt={line.productName}
              className={styles.productImageLarge}
              src={line.productImage?.image || line.productImage?.thumbnail}
            />
          </DialogContent>
        )}
      </Dialog>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Product-Name</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell align="right">QTY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.lines?.map((line) => (
            <Tooltip
              arrow
              key={line.id}
              placement={"top"}
              title={
                <Box padding={1}>
                  <Box
                    lineHeight="1.2em"
                    fontSize={14}
                    mb={1}
                    display="flex"
                    justifyContent="space-between"
                  >
                    {line.productName}
                  </Box>
                  <Box lineHeight="1.2em" fontSize={16} mb={1}>
                    {line.productSku}
                  </Box>
                  <Box textAlign="center">
                    <Avatar
                      variant="rounded"
                      className={styles.productImageBig}
                      src={
                        line.productImage?.image || line.productImage?.thumbnail
                      }
                    >
                      {false}
                    </Avatar>
                  </Box>
                </Box>
              }
            >
              <TableRow hover onClick={() => toggleLine(line)}>
                <TableCell className={styles.productImageCell}>
                  <Avatar
                    variant="rounded"
                    className={styles.productImage}
                    src={
                      line.productImage?.thumbnail || line.productImage?.image
                    }
                  >
                    {false}
                  </Avatar>
                </TableCell>
                <TableCell>{line.productName}</TableCell>
                <TableCell>
                  <Typography noWrap variant="body2">
                    {line.productSku}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  className={cx({
                    [styles.pickedItemPartialMissing]:
                      order?.pickedItems?.[line.productSku] &&
                      order?.pickedItems?.[line.productSku] - line.quantity < 0,
                    [styles.pickedItemMissing]:
                      order?.pickedItems &&
                      (order?.pickedItems?.[line.productSku] ?? 0) === 0,
                  })}
                >
                  {order?.pickedItems
                    ? (order?.pickedItems?.[line.productSku] ?? 0) + "/"
                    : ""}
                  {line.quantity}
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
